.static {
  position: static !important
}

.fixed {
  position: fixed !important
}

.absolute {
  position: absolute !important
}

.relative {
  position: relative !important
}

.sticky {
  position: sticky !important
}

.m-0 {
  margin: 0 !important
}

.m-1 {
  margin: 1rem !important
}

.m-2 {
  margin: 2rem !important
}

.m-3 {
  margin: 3rem !important
}

.m-4 {
  margin: 4rem !important
}

.m-5 {
  margin: 5rem !important
}

.m-auto {
  margin: auto !important
}

.m-05 {
  margin: 0.5rem !important
}

.-m-1 {
  margin: -1rem !important
}

.-m-2 {
  margin: -2rem !important
}

.-m-3 {
  margin: -3rem !important
}

.-m-4 {
  margin: -4rem !important
}

.-m-5 {
  margin: -5rem !important
}

.-m-05 {
  margin: -0.5rem !important
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important
}

.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important
}

.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important
}

.mx-3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important
}

.my-4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important
}

.mx-4 {
  margin-left: 4rem !important;
  margin-right: 4rem !important
}

.my-5 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important
}

.mx-5 {
  margin-left: 5rem !important;
  margin-right: 5rem !important
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.my-05 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important
}

.mx-05 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important
}

.-my-1 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important
}

.-mx-1 {
  margin-left: -1rem !important;
  margin-right: -1rem !important
}

.-my-2 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important
}

.-mx-2 {
  margin-left: -2rem !important;
  margin-right: -2rem !important
}

.-my-3 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important
}

.-mx-3 {
  margin-left: -3rem !important;
  margin-right: -3rem !important
}

.-my-4 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important
}

.-mx-4 {
  margin-left: -4rem !important;
  margin-right: -4rem !important
}

.-my-5 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important
}

.-mx-5 {
  margin-left: -5rem !important;
  margin-right: -5rem !important
}

.-my-05 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important
}

.-mx-05 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important
}

.mt-0 {
  margin-top: 0 !important
}

.mr-0 {
  margin-right: 0 !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.ml-0 {
  margin-left: 0 !important
}

.mt-1 {
  margin-top: 1rem !important
}

.mr-1 {
  margin-right: 1rem !important
}

.mb-1 {
  margin-bottom: 1rem !important
}

.ml-1 {
  margin-left: 1rem !important
}

.mt-2 {
  margin-top: 2rem !important
}

.mr-2 {
  margin-right: 2rem !important
}

.mb-2 {
  margin-bottom: 2rem !important
}

.ml-2 {
  margin-left: 2rem !important
}

.mt-3 {
  margin-top: 3rem !important
}

.mr-3 {
  margin-right: 3rem !important
}

.mb-3 {
  margin-bottom: 3rem !important
}

.ml-3 {
  margin-left: 3rem !important
}

.mt-4 {
  margin-top: 4rem !important
}

.mr-4 {
  margin-right: 4rem !important
}

.mb-4 {
  margin-bottom: 4rem !important
}

.ml-4 {
  margin-left: 4rem !important
}

.mt-5 {
  margin-top: 5rem !important
}

.mr-5 {
  margin-right: 5rem !important
}

.mb-5 {
  margin-bottom: 5rem !important
}

.ml-5 {
  margin-left: 5rem !important
}

.mt-auto {
  margin-top: auto !important
}

.mr-auto {
  margin-right: auto !important
}

.mb-auto {
  margin-bottom: auto !important
}

.ml-auto {
  margin-left: auto !important
}

.mt-05 {
  margin-top: 0.5rem !important
}

.mr-05 {
  margin-right: 0.5rem !important
}

.mb-05 {
  margin-bottom: 0.5rem !important
}

.ml-05 {
  margin-left: 0.5rem !important
}

.-mt-1 {
  margin-top: -1rem !important
}

.-mr-1 {
  margin-right: -1rem !important
}

.-mb-1 {
  margin-bottom: -1rem !important
}

.-ml-1 {
  margin-left: -1rem !important
}

.-mt-2 {
  margin-top: -2rem !important
}

.-mr-2 {
  margin-right: -2rem !important
}

.-mb-2 {
  margin-bottom: -2rem !important
}

.-ml-2 {
  margin-left: -2rem !important
}

.-mt-3 {
  margin-top: -3rem !important
}

.-mr-3 {
  margin-right: -3rem !important
}

.-mb-3 {
  margin-bottom: -3rem !important
}

.-ml-3 {
  margin-left: -3rem !important
}

.-mt-4 {
  margin-top: -4rem !important
}

.-mr-4 {
  margin-right: -4rem !important
}

.-mb-4 {
  margin-bottom: -4rem !important
}

.-ml-4 {
  margin-left: -4rem !important
}

.-mt-5 {
  margin-top: -5rem !important
}

.-mr-5 {
  margin-right: -5rem !important
}

.-mb-5 {
  margin-bottom: -5rem !important
}

.-ml-5 {
  margin-left: -5rem !important
}

.-mt-05 {
  margin-top: -0.5rem !important
}

.-mr-05 {
  margin-right: -0.5rem !important
}

.-mb-05 {
  margin-bottom: -0.5rem !important
}

.-ml-05 {
  margin-left: -0.5rem !important
}

.p-0 {
  padding: 0 !important
}

.p-1 {
  padding: 1rem !important
}

.p-2 {
  padding: 2rem !important
}

.p-3 {
  padding: 3rem !important
}

.p-4 {
  padding: 4rem !important
}

.p-5 {
  padding: 5rem !important
}

.p-05 {
  padding: 0.5rem !important
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important
}

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important
}

.px-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important
}

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important
}

.px-4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important
}

.py-5 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important
}

.px-5 {
  padding-left: 5rem !important;
  padding-right: 5rem !important
}

.py-05 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important
}

.px-05 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important
}

.pt-0 {
  padding-top: 0 !important
}

.pr-0 {
  padding-right: 0 !important
}

.pb-0 {
  padding-bottom: 0 !important
}

.pl-0 {
  padding-left: 0 !important
}

.pt-1 {
  padding-top: 1rem !important
}

.pr-1 {
  padding-right: 1rem !important
}

.pb-1 {
  padding-bottom: 1rem !important
}

.pl-1 {
  padding-left: 1rem !important
}

.pt-2 {
  padding-top: 2rem !important
}

.pr-2 {
  padding-right: 2rem !important
}

.pb-2 {
  padding-bottom: 2rem !important
}

.pl-2 {
  padding-left: 2rem !important
}

.pt-3 {
  padding-top: 3rem !important
}

.pr-3 {
  padding-right: 3rem !important
}

.pb-3 {
  padding-bottom: 3rem !important
}

.pl-3 {
  padding-left: 3rem !important
}

.pt-4 {
  padding-top: 4rem !important
}

.pr-4 {
  padding-right: 4rem !important
}

.pb-4 {
  padding-bottom: 4rem !important
}

.pl-4 {
  padding-left: 4rem !important
}

.pt-5 {
  padding-top: 5rem !important
}

.pr-5 {
  padding-right: 5rem !important
}

.pb-5 {
  padding-bottom: 5rem !important
}

.pl-5 {
  padding-left: 5rem !important
}

.pt-05 {
  padding-top: 0.5rem !important
}

.pr-05 {
  padding-right: 0.5rem !important
}

.pb-05 {
  padding-bottom: 0.5rem !important
}

.pl-05 {
  padding-left: 0.5rem !important
}

.w-0 {
  width: 0 !important
}

.w-1 {
  width: 1rem !important
}

.w-2 {
  width: 2rem !important
}

.w-3 {
  width: 3rem !important
}

.w-4 {
  width: 4rem !important
}

.w-5 {
  width: 5rem !important
}

.w-auto {
  width: auto !important
}

.w-05 {
  width: 0.5rem !important
}

.w-1\/2 {
  width: 50% !important
}

.w-1\/3 {
  width: 33.333333% !important
}

.w-2\/3 {
  width: 66.666667% !important
}

.w-1\/4 {
  width: 25% !important
}

.w-2\/4 {
  width: 50% !important
}

.w-3\/4 {
  width: 75% !important
}

.w-1\/5 {
  width: 20% !important
}

.w-2\/5 {
  width: 40% !important
}

.w-3\/5 {
  width: 60% !important
}

.w-4\/5 {
  width: 80% !important
}

.w-1\/6 {
  width: 16.666667% !important
}

.w-2\/6 {
  width: 33.333333% !important
}

.w-3\/6 {
  width: 50% !important
}

.w-4\/6 {
  width: 66.666667% !important
}

.w-5\/6 {
  width: 83.333333% !important
}

.w-1\/12 {
  width: 8.333333% !important
}

.w-2\/12 {
  width: 16.666667% !important
}

.w-3\/12 {
  width: 25% !important
}

.w-4\/12 {
  width: 33.333333% !important
}

.w-5\/12 {
  width: 41.666667% !important
}

.w-6\/12 {
  width: 50% !important
}

.w-7\/12 {
  width: 58.333333% !important
}

.w-8\/12 {
  width: 66.666667% !important
}

.w-9\/12 {
  width: 75% !important
}

.w-10\/12 {
  width: 83.333333% !important
}

.w-11\/12 {
  width: 91.666667% !important
}

.w-full {
  width: 100% !important
}

.w-screen {
  width: 100vw !important
}

.h-0 {
  height: 0 !important
}

.h-1 {
  height: 1rem !important
}

.h-2 {
  height: 2rem !important
}

.h-3 {
  height: 3rem !important
}

.h-4 {
  height: 4rem !important
}

.h-5 {
  height: 5rem !important
}

.h-auto {
  height: auto !important
}

.h-05 {
  height: 0.5rem !important
}

.h-full {
  height: 100% !important
}

.h-screen {
  height: 100vh !important
}

.min-h-0 {
  min-height: 0 !important
}

.min-h-full {
  min-height: 100% !important
}

.min-h-screen {
  min-height: 100vh !important
}

.block {
  display: block !important
}

.inline-block {
  display: inline-block !important
}

.inline {
  display: inline !important
}

.flex {
  display: flex !important
}

.inline-flex {
  display: inline-flex !important
}

.table {
  display: table !important
}

.table-caption {
  display: table-caption !important
}

.table-cell {
  display: table-cell !important
}

.table-column {
  display: table-column !important
}

.table-column-group {
  display: table-column-group !important
}

.table-footer-group {
  display: table-footer-group !important
}

.table-header-group {
  display: table-header-group !important
}

.table-row-group {
  display: table-row-group !important
}

.table-row {
  display: table-row !important
}

.flow-root {
  display: flow-root !important
}

.grid {
  display: grid !important
}

.inline-grid {
  display: inline-grid !important
}

.contents {
  display: contents !important
}

.hidden {
  display: none !important
}

.hover\:block:hover {
  display: block !important
}

.hover\:inline-block:hover {
  display: inline-block !important
}

.hover\:inline:hover {
  display: inline !important
}

.hover\:flex:hover {
  display: flex !important
}

.hover\:inline-flex:hover {
  display: inline-flex !important
}

.hover\:table:hover {
  display: table !important
}

.hover\:table-caption:hover {
  display: table-caption !important
}

.hover\:table-cell:hover {
  display: table-cell !important
}

.hover\:table-column:hover {
  display: table-column !important
}

.hover\:table-column-group:hover {
  display: table-column-group !important
}

.hover\:table-footer-group:hover {
  display: table-footer-group !important
}

.hover\:table-header-group:hover {
  display: table-header-group !important
}

.hover\:table-row-group:hover {
  display: table-row-group !important
}

.hover\:table-row:hover {
  display: table-row !important
}

.hover\:flow-root:hover {
  display: flow-root !important
}

.hover\:grid:hover {
  display: grid !important
}

.hover\:inline-grid:hover {
  display: inline-grid !important
}

.hover\:contents:hover {
  display: contents !important
}

.hover\:hidden:hover {
  display: none !important
}

.flex-1 {
  flex: 1 1 0% !important
}

.flex-auto {
  flex: 1 1 auto !important
}

.flex-initial {
  flex: 0 1 auto !important
}

.flex-none {
  flex: none !important
}

.flex-row {
  flex-direction: row !important
}

.flex-row-reverse {
  flex-direction: row-reverse !important
}

.flex-col {
  flex-direction: column !important
}

.flex-col-reverse {
  flex-direction: column-reverse !important
}

.flex-grow-0 {
  flex-grow: 0 !important
}

.flex-grow {
  flex-grow: 1 !important
}

.flex-shrink-0 {
  flex-shrink: 0 !important
}

.flex-shrink {
  flex-shrink: 1 !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important
}

.flex-no-wrap {
  flex-wrap: nowrap !important
}

.justify-start {
  justify-content: flex-start !important
}

.justify-end {
  justify-content: flex-end !important
}

.justify-center {
  justify-content: center !important
}

.justify-between {
  justify-content: space-between !important
}

.justify-around {
  justify-content: space-around !important
}

.justify-evenly {
  justify-content: space-evenly !important
}

.items-start {
  align-items: flex-start !important
}

.items-end {
  align-items: flex-end !important
}

.items-center {
  align-items: center !important
}

.items-baseline {
  align-items: baseline !important
}

.items-stretch {
  align-items: stretch !important
}

.text-left {
  text-align: left !important
}

.text-center {
  text-align: center !important
}

.text-right {
  text-align: right !important
}

.text-justify {
  text-align: justify !important
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.overflow-visible {
  overflow: visible !important
}

.overflow-scroll {
  overflow: scroll !important
}

.overflow-x-auto {
  overflow-x: auto !important
}

.overflow-y-auto {
  overflow-y: auto !important
}

.overflow-x-hidden {
  overflow-x: hidden !important
}

.overflow-y-hidden {
  overflow-y: hidden !important
}

.overflow-x-visible {
  overflow-x: visible !important
}

.overflow-y-visible {
  overflow-y: visible !important
}

.overflow-x-scroll {
  overflow-x: scroll !important
}

.overflow-y-scroll {
  overflow-y: scroll !important
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch !important
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto !important
}

.font-hairline {
  font-weight: 100 !important
}

.font-thin {
  font-weight: 200 !important
}

.font-light {
  font-weight: 300 !important
}

.font-normal {
  font-weight: 400 !important
}

.font-medium {
  font-weight: 500 !important
}

.font-semibold {
  font-weight: 600 !important
}

.font-bold {
  font-weight: 700 !important
}

.font-extrabold {
  font-weight: 800 !important
}

.font-black {
  font-weight: 900 !important
}

.hover\:font-hairline:hover {
  font-weight: 100 !important
}

.hover\:font-thin:hover {
  font-weight: 200 !important
}

.hover\:font-light:hover {
  font-weight: 300 !important
}

.hover\:font-normal:hover {
  font-weight: 400 !important
}

.hover\:font-medium:hover {
  font-weight: 500 !important
}

.hover\:font-semibold:hover {
  font-weight: 600 !important
}

.hover\:font-bold:hover {
  font-weight: 700 !important
}

.hover\:font-extrabold:hover {
  font-weight: 800 !important
}

.hover\:font-black:hover {
  font-weight: 900 !important
}

.focus\:font-hairline:focus {
  font-weight: 100 !important
}

.focus\:font-thin:focus {
  font-weight: 200 !important
}

.focus\:font-light:focus {
  font-weight: 300 !important
}

.focus\:font-normal:focus {
  font-weight: 400 !important
}

.focus\:font-medium:focus {
  font-weight: 500 !important
}

.focus\:font-semibold:focus {
  font-weight: 600 !important
}

.focus\:font-bold:focus {
  font-weight: 700 !important
}

.focus\:font-extrabold:focus {
  font-weight: 800 !important
}

.focus\:font-black:focus {
  font-weight: 900 !important
}

.text-xs {
  font-size: 1rem !important
}

.text-sm {
  font-size: 1.2rem !important
}

.text-base {
  font-size: 1.5rem !important
}

.text-lg {
  font-size: 1.8rem !important
}

.text-xl {
  font-size: 2.2rem !important
}

.text-2xl {
  font-size: 2.6rem !important
}

.text-3xl {
  font-size: 3rem !important
}

.leading-3 {
  line-height: .75rem !important
}

.leading-4 {
  line-height: 1rem !important
}

.leading-5 {
  line-height: 1.25rem !important
}

.leading-6 {
  line-height: 1.5rem !important
}

.leading-7 {
  line-height: 1.75rem !important
}

.leading-8 {
  line-height: 2rem !important
}

.leading-9 {
  line-height: 2.25rem !important
}

.leading-10 {
  line-height: 2.5rem !important
}

.leading-none {
  line-height: 1 !important
}

.leading-tight {
  line-height: 1.25 !important
}

.leading-snug {
  line-height: 1.375 !important
}

.leading-normal {
  line-height: 1.5 !important
}

.leading-relaxed {
  line-height: 1.625 !important
}

.leading-loose {
  line-height: 2 !important
}

.list-none {
  list-style-type: none !important
}

.list-disc {
  list-style-type: disc !important
}

.list-decimal {
  list-style-type: decimal !important
}

.opacity-0 {
  opacity: 0 !important
}

.opacity-25 {
  opacity: 0.25 !important
}

.opacity-50 {
  opacity: 0.5 !important
}

.opacity-75 {
  opacity: 0.75 !important
}

.opacity-100 {
  opacity: 1 !important
}

.hover\:opacity-0:hover {
  opacity: 0 !important
}

.hover\:opacity-25:hover {
  opacity: 0.25 !important
}

.hover\:opacity-50:hover {
  opacity: 0.5 !important
}

.hover\:opacity-75:hover {
  opacity: 0.75 !important
}

.hover\:opacity-100:hover {
  opacity: 1 !important
}

.focus\:opacity-0:focus {
  opacity: 0 !important
}

.focus\:opacity-25:focus {
  opacity: 0.25 !important
}

.focus\:opacity-50:focus {
  opacity: 0.5 !important
}

.focus\:opacity-75:focus {
  opacity: 0.75 !important
}

.focus\:opacity-100:focus {
  opacity: 1 !important
}

.break-normal {
  word-wrap: normal !important;
  overflow-wrap: normal !important;
  word-break: normal !important
}

.break-words {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important
}

.break-all {
  word-break: break-all !important
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important
}

.not-sr-only {
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important
}

.focus\:sr-only:focus {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important
}

.focus\:not-sr-only:focus {
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important
}

.text-white {
  color: #FFFFFF !important
}

.text-black {
  color: #000000 !important
}

.text-darkblue {
  color: #131A50 !important
}

.text-main {
  color: #1C287F !important
}

.text-error {
  color: #D80027 !important
}

.text-warning {
  color: #FF8D27 !important
}

.text-action {
  color: #00b78a !important
}

.text-success {
  color: #03CE9C !important
}

.text-paleYellow {
  color: #FFE665 !important
}

.text-gray20 {
  color: #E3E4E8 !important
}

.text-gray40 {
  color: #C5C6D3 !important
}

.text-gray60 {
  color: #8589AD !important
}

.text-gray80 {
  color: rgba(66, 71, 112, 1) !important
}

.uppercase {
  text-transform: uppercase !important
}

.lowercase {
  text-transform: lowercase !important
}

.capitalize {
  text-transform: capitalize !important
}

.normal-case {
  text-transform: none !important
}

.bg-white {
  background-color: #FFFFFF !important
}

.bg-black {
  background-color: #000000 !important
}

.bg-darkblue {
  background-color: #131A50 !important
}

.bg-main {
  background-color: #1C287F !important
}

.bg-error {
  background-color: #D80027 !important
}

.bg-warning {
  background-color: #FF8D27 !important
}

.bg-action {
  background-color: #00b78a !important
}

.bg-success {
  background-color: #03CE9C !important
}

.bg-paleYellow {
  background-color: #FFE665 !important
}

.bg-gray20 {
  background-color: #E3E4E8 !important
}

.bg-gray40 {
  background-color: #C5C6D3 !important
}

.bg-gray60 {
  background-color: #8589AD !important
}

.bg-gray80 {
  background-color: rgba(66, 71, 112, 1) !important
}

.select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important
}

.select-text {
  -webkit-user-select: text !important;
     -moz-user-select: text !important;
      -ms-user-select: text !important;
          user-select: text !important
}

.select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important
}

.select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important
}

.border-collapse {
  border-collapse: collapse !important
}

.border-separate {
  border-collapse: separate !important
}

.cursor-auto {
  cursor: auto !important
}

.cursor-default {
  cursor: default !important
}

.cursor-pointer {
  cursor: pointer !important
}

.cursor-wait {
  cursor: wait !important
}

.cursor-text {
  cursor: text !important
}

.cursor-move {
  cursor: move !important
}

.cursor-not-allowed {
  cursor: not-allowed !important
}

.whitespace-normal {
  white-space: normal !important
}

.whitespace-no-wrap {
  white-space: nowrap !important
}

.whitespace-pre {
  white-space: pre !important
}

.whitespace-pre-line {
  white-space: pre-line !important
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important
}

.underline {
  text-decoration: underline !important
}

.line-through {
  text-decoration: line-through !important
}

.no-underline {
  text-decoration: none !important
}

.hover\:underline:hover {
  text-decoration: underline !important
}

.hover\:line-through:hover {
  text-decoration: line-through !important
}

.hover\:no-underline:hover {
  text-decoration: none !important
}

.focus\:underline:focus {
  text-decoration: underline !important
}

.focus\:line-through:focus {
  text-decoration: line-through !important
}

.focus\:no-underline:focus {
  text-decoration: none !important
}

.grid-flow-row {
  grid-auto-flow: row !important
}

.grid-flow-col {
  grid-auto-flow: column !important
}

.grid-flow-row-dense {
  grid-auto-flow: row dense !important
}

.grid-flow-col-dense {
  grid-auto-flow: column dense !important
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr)) !important
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr)) !important
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr)) !important
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr)) !important
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr)) !important
}

.grid-rows-none {
  grid-template-rows: none !important
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr)) !important
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important
}

.grid-cols-none {
  grid-template-columns: none !important
}

.auto-rows-auto {
  grid-auto-rows: auto !important
}

.auto-rows-min {
  grid-auto-rows: -webkit-min-content !important;
  grid-auto-rows: min-content !important
}

.auto-rows-max {
  grid-auto-rows: -webkit-max-content !important;
  grid-auto-rows: max-content !important
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr) !important
}

.auto-cols-auto {
  grid-auto-columns: auto !important
}

.auto-cols-min {
  grid-auto-columns: -webkit-min-content !important;
  grid-auto-columns: min-content !important
}

.auto-cols-max {
  grid-auto-columns: -webkit-max-content !important;
  grid-auto-columns: max-content !important
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr) !important
}

.gap-0 {
  grid-gap: 0 !important;
  gap: 0 !important
}

.gap-1 {
  grid-gap: 1rem !important;
  gap: 1rem !important
}

.gap-2 {
  grid-gap: 2rem !important;
  gap: 2rem !important
}

.gap-3 {
  grid-gap: 3rem !important;
  gap: 3rem !important
}

.gap-4 {
  grid-gap: 4rem !important;
  gap: 4rem !important
}

.gap-5 {
  grid-gap: 5rem !important;
  gap: 5rem !important
}

.gap-05 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important
}

.col-gap-0 {
  grid-column-gap: 0 !important;
  -moz-column-gap: 0 !important;
       column-gap: 0 !important
}

.col-gap-1 {
  grid-column-gap: 1rem !important;
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important
}

.col-gap-2 {
  grid-column-gap: 2rem !important;
  -moz-column-gap: 2rem !important;
       column-gap: 2rem !important
}

.col-gap-3 {
  grid-column-gap: 3rem !important;
  -moz-column-gap: 3rem !important;
       column-gap: 3rem !important
}

.col-gap-4 {
  grid-column-gap: 4rem !important;
  -moz-column-gap: 4rem !important;
       column-gap: 4rem !important
}

.col-gap-5 {
  grid-column-gap: 5rem !important;
  -moz-column-gap: 5rem !important;
       column-gap: 5rem !important
}

.col-gap-05 {
  grid-column-gap: 0.5rem !important;
  -moz-column-gap: 0.5rem !important;
       column-gap: 0.5rem !important
}

.gap-x-0 {
  grid-column-gap: 0 !important;
  -moz-column-gap: 0 !important;
       column-gap: 0 !important
}

.gap-x-1 {
  grid-column-gap: 1rem !important;
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important
}

.gap-x-2 {
  grid-column-gap: 2rem !important;
  -moz-column-gap: 2rem !important;
       column-gap: 2rem !important
}

.gap-x-3 {
  grid-column-gap: 3rem !important;
  -moz-column-gap: 3rem !important;
       column-gap: 3rem !important
}

.gap-x-4 {
  grid-column-gap: 4rem !important;
  -moz-column-gap: 4rem !important;
       column-gap: 4rem !important
}

.gap-x-5 {
  grid-column-gap: 5rem !important;
  -moz-column-gap: 5rem !important;
       column-gap: 5rem !important
}

.gap-x-05 {
  grid-column-gap: 0.5rem !important;
  -moz-column-gap: 0.5rem !important;
       column-gap: 0.5rem !important
}

.row-gap-0 {
  grid-row-gap: 0 !important;
  row-gap: 0 !important
}

.row-gap-1 {
  grid-row-gap: 1rem !important;
  row-gap: 1rem !important
}

.row-gap-2 {
  grid-row-gap: 2rem !important;
  row-gap: 2rem !important
}

.row-gap-3 {
  grid-row-gap: 3rem !important;
  row-gap: 3rem !important
}

.row-gap-4 {
  grid-row-gap: 4rem !important;
  row-gap: 4rem !important
}

.row-gap-5 {
  grid-row-gap: 5rem !important;
  row-gap: 5rem !important
}

.row-gap-05 {
  grid-row-gap: 0.5rem !important;
  row-gap: 0.5rem !important
}

.gap-y-0 {
  grid-row-gap: 0 !important;
  row-gap: 0 !important
}

.gap-y-1 {
  grid-row-gap: 1rem !important;
  row-gap: 1rem !important
}

.gap-y-2 {
  grid-row-gap: 2rem !important;
  row-gap: 2rem !important
}

.gap-y-3 {
  grid-row-gap: 3rem !important;
  row-gap: 3rem !important
}

.gap-y-4 {
  grid-row-gap: 4rem !important;
  row-gap: 4rem !important
}

.gap-y-5 {
  grid-row-gap: 5rem !important;
  row-gap: 5rem !important
}

.gap-y-05 {
  grid-row-gap: 0.5rem !important;
  row-gap: 0.5rem !important
}

@media (min-width: 640px) {
  .sm\:static {
    position: static !important
  }

  .sm\:fixed {
    position: fixed !important
  }

  .sm\:absolute {
    position: absolute !important
  }

  .sm\:relative {
    position: relative !important
  }

  .sm\:sticky {
    position: sticky !important
  }

  .sm\:m-0 {
    margin: 0 !important
  }

  .sm\:m-1 {
    margin: 1rem !important
  }

  .sm\:m-2 {
    margin: 2rem !important
  }

  .sm\:m-3 {
    margin: 3rem !important
  }

  .sm\:m-4 {
    margin: 4rem !important
  }

  .sm\:m-5 {
    margin: 5rem !important
  }

  .sm\:m-auto {
    margin: auto !important
  }

  .sm\:m-05 {
    margin: 0.5rem !important
  }

  .sm\:-m-1 {
    margin: -1rem !important
  }

  .sm\:-m-2 {
    margin: -2rem !important
  }

  .sm\:-m-3 {
    margin: -3rem !important
  }

  .sm\:-m-4 {
    margin: -4rem !important
  }

  .sm\:-m-5 {
    margin: -5rem !important
  }

  .sm\:-m-05 {
    margin: -0.5rem !important
  }

  .sm\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .sm\:my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .sm\:mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
  }

  .sm\:my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important
  }

  .sm\:mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
  }

  .sm\:my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .sm\:mx-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
  }

  .sm\:my-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important
  }

  .sm\:mx-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
  }

  .sm\:my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important
  }

  .sm\:mx-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
  }

  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .sm\:my-05 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important
  }

  .sm\:mx-05 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important
  }

  .sm\:-my-1 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important
  }

  .sm\:-mx-1 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
  }

  .sm\:-my-2 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important
  }

  .sm\:-mx-2 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
  }

  .sm\:-my-3 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important
  }

  .sm\:-mx-3 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
  }

  .sm\:-my-4 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important
  }

  .sm\:-mx-4 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
  }

  .sm\:-my-5 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important
  }

  .sm\:-mx-5 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
  }

  .sm\:-my-05 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important
  }

  .sm\:-mx-05 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important
  }

  .sm\:mt-0 {
    margin-top: 0 !important
  }

  .sm\:mr-0 {
    margin-right: 0 !important
  }

  .sm\:mb-0 {
    margin-bottom: 0 !important
  }

  .sm\:ml-0 {
    margin-left: 0 !important
  }

  .sm\:mt-1 {
    margin-top: 1rem !important
  }

  .sm\:mr-1 {
    margin-right: 1rem !important
  }

  .sm\:mb-1 {
    margin-bottom: 1rem !important
  }

  .sm\:ml-1 {
    margin-left: 1rem !important
  }

  .sm\:mt-2 {
    margin-top: 2rem !important
  }

  .sm\:mr-2 {
    margin-right: 2rem !important
  }

  .sm\:mb-2 {
    margin-bottom: 2rem !important
  }

  .sm\:ml-2 {
    margin-left: 2rem !important
  }

  .sm\:mt-3 {
    margin-top: 3rem !important
  }

  .sm\:mr-3 {
    margin-right: 3rem !important
  }

  .sm\:mb-3 {
    margin-bottom: 3rem !important
  }

  .sm\:ml-3 {
    margin-left: 3rem !important
  }

  .sm\:mt-4 {
    margin-top: 4rem !important
  }

  .sm\:mr-4 {
    margin-right: 4rem !important
  }

  .sm\:mb-4 {
    margin-bottom: 4rem !important
  }

  .sm\:ml-4 {
    margin-left: 4rem !important
  }

  .sm\:mt-5 {
    margin-top: 5rem !important
  }

  .sm\:mr-5 {
    margin-right: 5rem !important
  }

  .sm\:mb-5 {
    margin-bottom: 5rem !important
  }

  .sm\:ml-5 {
    margin-left: 5rem !important
  }

  .sm\:mt-auto {
    margin-top: auto !important
  }

  .sm\:mr-auto {
    margin-right: auto !important
  }

  .sm\:mb-auto {
    margin-bottom: auto !important
  }

  .sm\:ml-auto {
    margin-left: auto !important
  }

  .sm\:mt-05 {
    margin-top: 0.5rem !important
  }

  .sm\:mr-05 {
    margin-right: 0.5rem !important
  }

  .sm\:mb-05 {
    margin-bottom: 0.5rem !important
  }

  .sm\:ml-05 {
    margin-left: 0.5rem !important
  }

  .sm\:-mt-1 {
    margin-top: -1rem !important
  }

  .sm\:-mr-1 {
    margin-right: -1rem !important
  }

  .sm\:-mb-1 {
    margin-bottom: -1rem !important
  }

  .sm\:-ml-1 {
    margin-left: -1rem !important
  }

  .sm\:-mt-2 {
    margin-top: -2rem !important
  }

  .sm\:-mr-2 {
    margin-right: -2rem !important
  }

  .sm\:-mb-2 {
    margin-bottom: -2rem !important
  }

  .sm\:-ml-2 {
    margin-left: -2rem !important
  }

  .sm\:-mt-3 {
    margin-top: -3rem !important
  }

  .sm\:-mr-3 {
    margin-right: -3rem !important
  }

  .sm\:-mb-3 {
    margin-bottom: -3rem !important
  }

  .sm\:-ml-3 {
    margin-left: -3rem !important
  }

  .sm\:-mt-4 {
    margin-top: -4rem !important
  }

  .sm\:-mr-4 {
    margin-right: -4rem !important
  }

  .sm\:-mb-4 {
    margin-bottom: -4rem !important
  }

  .sm\:-ml-4 {
    margin-left: -4rem !important
  }

  .sm\:-mt-5 {
    margin-top: -5rem !important
  }

  .sm\:-mr-5 {
    margin-right: -5rem !important
  }

  .sm\:-mb-5 {
    margin-bottom: -5rem !important
  }

  .sm\:-ml-5 {
    margin-left: -5rem !important
  }

  .sm\:-mt-05 {
    margin-top: -0.5rem !important
  }

  .sm\:-mr-05 {
    margin-right: -0.5rem !important
  }

  .sm\:-mb-05 {
    margin-bottom: -0.5rem !important
  }

  .sm\:-ml-05 {
    margin-left: -0.5rem !important
  }

  .sm\:p-0 {
    padding: 0 !important
  }

  .sm\:p-1 {
    padding: 1rem !important
  }

  .sm\:p-2 {
    padding: 2rem !important
  }

  .sm\:p-3 {
    padding: 3rem !important
  }

  .sm\:p-4 {
    padding: 4rem !important
  }

  .sm\:p-5 {
    padding: 5rem !important
  }

  .sm\:p-05 {
    padding: 0.5rem !important
  }

  .sm\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .sm\:py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .sm\:px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }

  .sm\:py-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important
  }

  .sm\:px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }

  .sm\:py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .sm\:px-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
  }

  .sm\:py-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important
  }

  .sm\:px-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
  }

  .sm\:py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important
  }

  .sm\:px-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
  }

  .sm\:py-05 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important
  }

  .sm\:px-05 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important
  }

  .sm\:pt-0 {
    padding-top: 0 !important
  }

  .sm\:pr-0 {
    padding-right: 0 !important
  }

  .sm\:pb-0 {
    padding-bottom: 0 !important
  }

  .sm\:pl-0 {
    padding-left: 0 !important
  }

  .sm\:pt-1 {
    padding-top: 1rem !important
  }

  .sm\:pr-1 {
    padding-right: 1rem !important
  }

  .sm\:pb-1 {
    padding-bottom: 1rem !important
  }

  .sm\:pl-1 {
    padding-left: 1rem !important
  }

  .sm\:pt-2 {
    padding-top: 2rem !important
  }

  .sm\:pr-2 {
    padding-right: 2rem !important
  }

  .sm\:pb-2 {
    padding-bottom: 2rem !important
  }

  .sm\:pl-2 {
    padding-left: 2rem !important
  }

  .sm\:pt-3 {
    padding-top: 3rem !important
  }

  .sm\:pr-3 {
    padding-right: 3rem !important
  }

  .sm\:pb-3 {
    padding-bottom: 3rem !important
  }

  .sm\:pl-3 {
    padding-left: 3rem !important
  }

  .sm\:pt-4 {
    padding-top: 4rem !important
  }

  .sm\:pr-4 {
    padding-right: 4rem !important
  }

  .sm\:pb-4 {
    padding-bottom: 4rem !important
  }

  .sm\:pl-4 {
    padding-left: 4rem !important
  }

  .sm\:pt-5 {
    padding-top: 5rem !important
  }

  .sm\:pr-5 {
    padding-right: 5rem !important
  }

  .sm\:pb-5 {
    padding-bottom: 5rem !important
  }

  .sm\:pl-5 {
    padding-left: 5rem !important
  }

  .sm\:pt-05 {
    padding-top: 0.5rem !important
  }

  .sm\:pr-05 {
    padding-right: 0.5rem !important
  }

  .sm\:pb-05 {
    padding-bottom: 0.5rem !important
  }

  .sm\:pl-05 {
    padding-left: 0.5rem !important
  }

  .sm\:w-0 {
    width: 0 !important
  }

  .sm\:w-1 {
    width: 1rem !important
  }

  .sm\:w-2 {
    width: 2rem !important
  }

  .sm\:w-3 {
    width: 3rem !important
  }

  .sm\:w-4 {
    width: 4rem !important
  }

  .sm\:w-5 {
    width: 5rem !important
  }

  .sm\:w-auto {
    width: auto !important
  }

  .sm\:w-05 {
    width: 0.5rem !important
  }

  .sm\:w-1\/2 {
    width: 50% !important
  }

  .sm\:w-1\/3 {
    width: 33.333333% !important
  }

  .sm\:w-2\/3 {
    width: 66.666667% !important
  }

  .sm\:w-1\/4 {
    width: 25% !important
  }

  .sm\:w-2\/4 {
    width: 50% !important
  }

  .sm\:w-3\/4 {
    width: 75% !important
  }

  .sm\:w-1\/5 {
    width: 20% !important
  }

  .sm\:w-2\/5 {
    width: 40% !important
  }

  .sm\:w-3\/5 {
    width: 60% !important
  }

  .sm\:w-4\/5 {
    width: 80% !important
  }

  .sm\:w-1\/6 {
    width: 16.666667% !important
  }

  .sm\:w-2\/6 {
    width: 33.333333% !important
  }

  .sm\:w-3\/6 {
    width: 50% !important
  }

  .sm\:w-4\/6 {
    width: 66.666667% !important
  }

  .sm\:w-5\/6 {
    width: 83.333333% !important
  }

  .sm\:w-1\/12 {
    width: 8.333333% !important
  }

  .sm\:w-2\/12 {
    width: 16.666667% !important
  }

  .sm\:w-3\/12 {
    width: 25% !important
  }

  .sm\:w-4\/12 {
    width: 33.333333% !important
  }

  .sm\:w-5\/12 {
    width: 41.666667% !important
  }

  .sm\:w-6\/12 {
    width: 50% !important
  }

  .sm\:w-7\/12 {
    width: 58.333333% !important
  }

  .sm\:w-8\/12 {
    width: 66.666667% !important
  }

  .sm\:w-9\/12 {
    width: 75% !important
  }

  .sm\:w-10\/12 {
    width: 83.333333% !important
  }

  .sm\:w-11\/12 {
    width: 91.666667% !important
  }

  .sm\:w-full {
    width: 100% !important
  }

  .sm\:w-screen {
    width: 100vw !important
  }

  .sm\:h-0 {
    height: 0 !important
  }

  .sm\:h-1 {
    height: 1rem !important
  }

  .sm\:h-2 {
    height: 2rem !important
  }

  .sm\:h-3 {
    height: 3rem !important
  }

  .sm\:h-4 {
    height: 4rem !important
  }

  .sm\:h-5 {
    height: 5rem !important
  }

  .sm\:h-auto {
    height: auto !important
  }

  .sm\:h-05 {
    height: 0.5rem !important
  }

  .sm\:h-full {
    height: 100% !important
  }

  .sm\:h-screen {
    height: 100vh !important
  }

  .sm\:min-h-0 {
    min-height: 0 !important
  }

  .sm\:min-h-full {
    min-height: 100% !important
  }

  .sm\:min-h-screen {
    min-height: 100vh !important
  }

  .sm\:block {
    display: block !important
  }

  .sm\:inline-block {
    display: inline-block !important
  }

  .sm\:inline {
    display: inline !important
  }

  .sm\:flex {
    display: flex !important
  }

  .sm\:inline-flex {
    display: inline-flex !important
  }

  .sm\:table {
    display: table !important
  }

  .sm\:table-caption {
    display: table-caption !important
  }

  .sm\:table-cell {
    display: table-cell !important
  }

  .sm\:table-column {
    display: table-column !important
  }

  .sm\:table-column-group {
    display: table-column-group !important
  }

  .sm\:table-footer-group {
    display: table-footer-group !important
  }

  .sm\:table-header-group {
    display: table-header-group !important
  }

  .sm\:table-row-group {
    display: table-row-group !important
  }

  .sm\:table-row {
    display: table-row !important
  }

  .sm\:flow-root {
    display: flow-root !important
  }

  .sm\:grid {
    display: grid !important
  }

  .sm\:inline-grid {
    display: inline-grid !important
  }

  .sm\:contents {
    display: contents !important
  }

  .sm\:hidden {
    display: none !important
  }

  .sm\:hover\:block:hover {
    display: block !important
  }

  .sm\:hover\:inline-block:hover {
    display: inline-block !important
  }

  .sm\:hover\:inline:hover {
    display: inline !important
  }

  .sm\:hover\:flex:hover {
    display: flex !important
  }

  .sm\:hover\:inline-flex:hover {
    display: inline-flex !important
  }

  .sm\:hover\:table:hover {
    display: table !important
  }

  .sm\:hover\:table-caption:hover {
    display: table-caption !important
  }

  .sm\:hover\:table-cell:hover {
    display: table-cell !important
  }

  .sm\:hover\:table-column:hover {
    display: table-column !important
  }

  .sm\:hover\:table-column-group:hover {
    display: table-column-group !important
  }

  .sm\:hover\:table-footer-group:hover {
    display: table-footer-group !important
  }

  .sm\:hover\:table-header-group:hover {
    display: table-header-group !important
  }

  .sm\:hover\:table-row-group:hover {
    display: table-row-group !important
  }

  .sm\:hover\:table-row:hover {
    display: table-row !important
  }

  .sm\:hover\:flow-root:hover {
    display: flow-root !important
  }

  .sm\:hover\:grid:hover {
    display: grid !important
  }

  .sm\:hover\:inline-grid:hover {
    display: inline-grid !important
  }

  .sm\:hover\:contents:hover {
    display: contents !important
  }

  .sm\:hover\:hidden:hover {
    display: none !important
  }

  .sm\:flex-1 {
    flex: 1 1 0% !important
  }

  .sm\:flex-auto {
    flex: 1 1 auto !important
  }

  .sm\:flex-initial {
    flex: 0 1 auto !important
  }

  .sm\:flex-none {
    flex: none !important
  }

  .sm\:flex-row {
    flex-direction: row !important
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important
  }

  .sm\:flex-col {
    flex-direction: column !important
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse !important
  }

  .sm\:flex-grow-0 {
    flex-grow: 0 !important
  }

  .sm\:flex-grow {
    flex-grow: 1 !important
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important
  }

  .sm\:flex-shrink {
    flex-shrink: 1 !important
  }

  .sm\:flex-wrap {
    flex-wrap: wrap !important
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .sm\:flex-no-wrap {
    flex-wrap: nowrap !important
  }

  .sm\:justify-start {
    justify-content: flex-start !important
  }

  .sm\:justify-end {
    justify-content: flex-end !important
  }

  .sm\:justify-center {
    justify-content: center !important
  }

  .sm\:justify-between {
    justify-content: space-between !important
  }

  .sm\:justify-around {
    justify-content: space-around !important
  }

  .sm\:justify-evenly {
    justify-content: space-evenly !important
  }

  .sm\:items-start {
    align-items: flex-start !important
  }

  .sm\:items-end {
    align-items: flex-end !important
  }

  .sm\:items-center {
    align-items: center !important
  }

  .sm\:items-baseline {
    align-items: baseline !important
  }

  .sm\:items-stretch {
    align-items: stretch !important
  }

  .sm\:text-left {
    text-align: left !important
  }

  .sm\:text-center {
    text-align: center !important
  }

  .sm\:text-right {
    text-align: right !important
  }

  .sm\:text-justify {
    text-align: justify !important
  }

  .sm\:overflow-auto {
    overflow: auto !important
  }

  .sm\:overflow-hidden {
    overflow: hidden !important
  }

  .sm\:overflow-visible {
    overflow: visible !important
  }

  .sm\:overflow-scroll {
    overflow: scroll !important
  }

  .sm\:overflow-x-auto {
    overflow-x: auto !important
  }

  .sm\:overflow-y-auto {
    overflow-y: auto !important
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden !important
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden !important
  }

  .sm\:overflow-x-visible {
    overflow-x: visible !important
  }

  .sm\:overflow-y-visible {
    overflow-y: visible !important
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll !important
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll !important
  }

  .sm\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important
  }

  .sm\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important
  }

  .sm\:font-hairline {
    font-weight: 100 !important
  }

  .sm\:font-thin {
    font-weight: 200 !important
  }

  .sm\:font-light {
    font-weight: 300 !important
  }

  .sm\:font-normal {
    font-weight: 400 !important
  }

  .sm\:font-medium {
    font-weight: 500 !important
  }

  .sm\:font-semibold {
    font-weight: 600 !important
  }

  .sm\:font-bold {
    font-weight: 700 !important
  }

  .sm\:font-extrabold {
    font-weight: 800 !important
  }

  .sm\:font-black {
    font-weight: 900 !important
  }

  .sm\:hover\:font-hairline:hover {
    font-weight: 100 !important
  }

  .sm\:hover\:font-thin:hover {
    font-weight: 200 !important
  }

  .sm\:hover\:font-light:hover {
    font-weight: 300 !important
  }

  .sm\:hover\:font-normal:hover {
    font-weight: 400 !important
  }

  .sm\:hover\:font-medium:hover {
    font-weight: 500 !important
  }

  .sm\:hover\:font-semibold:hover {
    font-weight: 600 !important
  }

  .sm\:hover\:font-bold:hover {
    font-weight: 700 !important
  }

  .sm\:hover\:font-extrabold:hover {
    font-weight: 800 !important
  }

  .sm\:hover\:font-black:hover {
    font-weight: 900 !important
  }

  .sm\:focus\:font-hairline:focus {
    font-weight: 100 !important
  }

  .sm\:focus\:font-thin:focus {
    font-weight: 200 !important
  }

  .sm\:focus\:font-light:focus {
    font-weight: 300 !important
  }

  .sm\:focus\:font-normal:focus {
    font-weight: 400 !important
  }

  .sm\:focus\:font-medium:focus {
    font-weight: 500 !important
  }

  .sm\:focus\:font-semibold:focus {
    font-weight: 600 !important
  }

  .sm\:focus\:font-bold:focus {
    font-weight: 700 !important
  }

  .sm\:focus\:font-extrabold:focus {
    font-weight: 800 !important
  }

  .sm\:focus\:font-black:focus {
    font-weight: 900 !important
  }

  .sm\:text-xs {
    font-size: 1rem !important
  }

  .sm\:text-sm {
    font-size: 1.2rem !important
  }

  .sm\:text-base {
    font-size: 1.5rem !important
  }

  .sm\:text-lg {
    font-size: 1.8rem !important
  }

  .sm\:text-xl {
    font-size: 2.2rem !important
  }

  .sm\:text-2xl {
    font-size: 2.6rem !important
  }

  .sm\:text-3xl {
    font-size: 3rem !important
  }

  .sm\:leading-3 {
    line-height: .75rem !important
  }

  .sm\:leading-4 {
    line-height: 1rem !important
  }

  .sm\:leading-5 {
    line-height: 1.25rem !important
  }

  .sm\:leading-6 {
    line-height: 1.5rem !important
  }

  .sm\:leading-7 {
    line-height: 1.75rem !important
  }

  .sm\:leading-8 {
    line-height: 2rem !important
  }

  .sm\:leading-9 {
    line-height: 2.25rem !important
  }

  .sm\:leading-10 {
    line-height: 2.5rem !important
  }

  .sm\:leading-none {
    line-height: 1 !important
  }

  .sm\:leading-tight {
    line-height: 1.25 !important
  }

  .sm\:leading-snug {
    line-height: 1.375 !important
  }

  .sm\:leading-normal {
    line-height: 1.5 !important
  }

  .sm\:leading-relaxed {
    line-height: 1.625 !important
  }

  .sm\:leading-loose {
    line-height: 2 !important
  }

  .sm\:list-none {
    list-style-type: none !important
  }

  .sm\:list-disc {
    list-style-type: disc !important
  }

  .sm\:list-decimal {
    list-style-type: decimal !important
  }

  .sm\:opacity-0 {
    opacity: 0 !important
  }

  .sm\:opacity-25 {
    opacity: 0.25 !important
  }

  .sm\:opacity-50 {
    opacity: 0.5 !important
  }

  .sm\:opacity-75 {
    opacity: 0.75 !important
  }

  .sm\:opacity-100 {
    opacity: 1 !important
  }

  .sm\:hover\:opacity-0:hover {
    opacity: 0 !important
  }

  .sm\:hover\:opacity-25:hover {
    opacity: 0.25 !important
  }

  .sm\:hover\:opacity-50:hover {
    opacity: 0.5 !important
  }

  .sm\:hover\:opacity-75:hover {
    opacity: 0.75 !important
  }

  .sm\:hover\:opacity-100:hover {
    opacity: 1 !important
  }

  .sm\:focus\:opacity-0:focus {
    opacity: 0 !important
  }

  .sm\:focus\:opacity-25:focus {
    opacity: 0.25 !important
  }

  .sm\:focus\:opacity-50:focus {
    opacity: 0.5 !important
  }

  .sm\:focus\:opacity-75:focus {
    opacity: 0.75 !important
  }

  .sm\:focus\:opacity-100:focus {
    opacity: 1 !important
  }

  .sm\:break-normal {
    word-wrap: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important
  }

  .sm\:break-words {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important
  }

  .sm\:break-all {
    word-break: break-all !important
  }

  .sm\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important
  }

  .sm\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important
  }

  .sm\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important
  }

  .sm\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important
  }

  .sm\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important
  }

  .sm\:text-white {
    color: #FFFFFF !important
  }

  .sm\:text-black {
    color: #000000 !important
  }

  .sm\:text-darkblue {
    color: #131A50 !important
  }

  .sm\:text-main {
    color: #1C287F !important
  }

  .sm\:text-error {
    color: #D80027 !important
  }

  .sm\:text-warning {
    color: #FF8D27 !important
  }

  .sm\:text-action {
    color: #00b78a !important
  }

  .sm\:text-success {
    color: #03CE9C !important
  }

  .sm\:text-paleYellow {
    color: #FFE665 !important
  }

  .sm\:text-gray20 {
    color: #E3E4E8 !important
  }

  .sm\:text-gray40 {
    color: #C5C6D3 !important
  }

  .sm\:text-gray60 {
    color: #8589AD !important
  }

  .sm\:text-gray80 {
    color: rgba(66, 71, 112, 1) !important
  }

  .sm\:bg-white {
    background-color: #FFFFFF !important
  }

  .sm\:bg-black {
    background-color: #000000 !important
  }

  .sm\:bg-darkblue {
    background-color: #131A50 !important
  }

  .sm\:bg-main {
    background-color: #1C287F !important
  }

  .sm\:bg-error {
    background-color: #D80027 !important
  }

  .sm\:bg-warning {
    background-color: #FF8D27 !important
  }

  .sm\:bg-action {
    background-color: #00b78a !important
  }

  .sm\:bg-success {
    background-color: #03CE9C !important
  }

  .sm\:bg-paleYellow {
    background-color: #FFE665 !important
  }

  .sm\:bg-gray20 {
    background-color: #E3E4E8 !important
  }

  .sm\:bg-gray40 {
    background-color: #C5C6D3 !important
  }

  .sm\:bg-gray60 {
    background-color: #8589AD !important
  }

  .sm\:bg-gray80 {
    background-color: rgba(66, 71, 112, 1) !important
  }

  .sm\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important
  }

  .sm\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important
  }

  .sm\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important
  }

  .sm\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important
  }

  .sm\:underline {
    text-decoration: underline !important
  }

  .sm\:line-through {
    text-decoration: line-through !important
  }

  .sm\:no-underline {
    text-decoration: none !important
  }

  .sm\:hover\:underline:hover {
    text-decoration: underline !important
  }

  .sm\:hover\:line-through:hover {
    text-decoration: line-through !important
  }

  .sm\:hover\:no-underline:hover {
    text-decoration: none !important
  }

  .sm\:focus\:underline:focus {
    text-decoration: underline !important
  }

  .sm\:focus\:line-through:focus {
    text-decoration: line-through !important
  }

  .sm\:focus\:no-underline:focus {
    text-decoration: none !important
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row !important
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column !important
  }

  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense !important
  }

  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense !important
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important
  }

  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important
  }

  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important
  }

  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important
  }

  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important
  }

  .sm\:grid-rows-none {
    grid-template-rows: none !important
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important
  }

  .sm\:grid-cols-none {
    grid-template-columns: none !important
  }

  .sm\:auto-rows-auto {
    grid-auto-rows: auto !important
  }

  .sm\:auto-rows-min {
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important
  }

  .sm\:auto-rows-max {
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important
  }

  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important
  }

  .sm\:auto-cols-auto {
    grid-auto-columns: auto !important
  }

  .sm\:auto-cols-min {
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important
  }

  .sm\:auto-cols-max {
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important
  }

  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important
  }

  .sm\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important
  }

  .sm\:gap-1 {
    grid-gap: 1rem !important;
    gap: 1rem !important
  }

  .sm\:gap-2 {
    grid-gap: 2rem !important;
    gap: 2rem !important
  }

  .sm\:gap-3 {
    grid-gap: 3rem !important;
    gap: 3rem !important
  }

  .sm\:gap-4 {
    grid-gap: 4rem !important;
    gap: 4rem !important
  }

  .sm\:gap-5 {
    grid-gap: 5rem !important;
    gap: 5rem !important
  }

  .sm\:gap-05 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important
  }

  .sm\:col-gap-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important
  }

  .sm\:col-gap-1 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important
  }

  .sm\:col-gap-2 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important
  }

  .sm\:col-gap-3 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important
  }

  .sm\:col-gap-4 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important
  }

  .sm\:col-gap-5 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important
  }

  .sm\:col-gap-05 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important
  }

  .sm\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important
  }

  .sm\:gap-x-1 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important
  }

  .sm\:gap-x-2 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important
  }

  .sm\:gap-x-3 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important
  }

  .sm\:gap-x-4 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important
  }

  .sm\:gap-x-5 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important
  }

  .sm\:gap-x-05 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important
  }

  .sm\:row-gap-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important
  }

  .sm\:row-gap-1 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important
  }

  .sm\:row-gap-2 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important
  }

  .sm\:row-gap-3 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important
  }

  .sm\:row-gap-4 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important
  }

  .sm\:row-gap-5 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important
  }

  .sm\:row-gap-05 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important
  }

  .sm\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important
  }

  .sm\:gap-y-1 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important
  }

  .sm\:gap-y-2 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important
  }

  .sm\:gap-y-3 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important
  }

  .sm\:gap-y-4 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important
  }

  .sm\:gap-y-5 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important
  }

  .sm\:gap-y-05 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important
  }
}

@media (min-width: 768px) {
  .md\:static {
    position: static !important
  }

  .md\:fixed {
    position: fixed !important
  }

  .md\:absolute {
    position: absolute !important
  }

  .md\:relative {
    position: relative !important
  }

  .md\:sticky {
    position: sticky !important
  }

  .md\:m-0 {
    margin: 0 !important
  }

  .md\:m-1 {
    margin: 1rem !important
  }

  .md\:m-2 {
    margin: 2rem !important
  }

  .md\:m-3 {
    margin: 3rem !important
  }

  .md\:m-4 {
    margin: 4rem !important
  }

  .md\:m-5 {
    margin: 5rem !important
  }

  .md\:m-auto {
    margin: auto !important
  }

  .md\:m-05 {
    margin: 0.5rem !important
  }

  .md\:-m-1 {
    margin: -1rem !important
  }

  .md\:-m-2 {
    margin: -2rem !important
  }

  .md\:-m-3 {
    margin: -3rem !important
  }

  .md\:-m-4 {
    margin: -4rem !important
  }

  .md\:-m-5 {
    margin: -5rem !important
  }

  .md\:-m-05 {
    margin: -0.5rem !important
  }

  .md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .md\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .md\:my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .md\:mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
  }

  .md\:my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important
  }

  .md\:mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
  }

  .md\:my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .md\:mx-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
  }

  .md\:my-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important
  }

  .md\:mx-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
  }

  .md\:my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important
  }

  .md\:mx-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
  }

  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .md\:my-05 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important
  }

  .md\:mx-05 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important
  }

  .md\:-my-1 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important
  }

  .md\:-mx-1 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
  }

  .md\:-my-2 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important
  }

  .md\:-mx-2 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
  }

  .md\:-my-3 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important
  }

  .md\:-mx-3 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
  }

  .md\:-my-4 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important
  }

  .md\:-mx-4 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
  }

  .md\:-my-5 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important
  }

  .md\:-mx-5 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
  }

  .md\:-my-05 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important
  }

  .md\:-mx-05 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important
  }

  .md\:mt-0 {
    margin-top: 0 !important
  }

  .md\:mr-0 {
    margin-right: 0 !important
  }

  .md\:mb-0 {
    margin-bottom: 0 !important
  }

  .md\:ml-0 {
    margin-left: 0 !important
  }

  .md\:mt-1 {
    margin-top: 1rem !important
  }

  .md\:mr-1 {
    margin-right: 1rem !important
  }

  .md\:mb-1 {
    margin-bottom: 1rem !important
  }

  .md\:ml-1 {
    margin-left: 1rem !important
  }

  .md\:mt-2 {
    margin-top: 2rem !important
  }

  .md\:mr-2 {
    margin-right: 2rem !important
  }

  .md\:mb-2 {
    margin-bottom: 2rem !important
  }

  .md\:ml-2 {
    margin-left: 2rem !important
  }

  .md\:mt-3 {
    margin-top: 3rem !important
  }

  .md\:mr-3 {
    margin-right: 3rem !important
  }

  .md\:mb-3 {
    margin-bottom: 3rem !important
  }

  .md\:ml-3 {
    margin-left: 3rem !important
  }

  .md\:mt-4 {
    margin-top: 4rem !important
  }

  .md\:mr-4 {
    margin-right: 4rem !important
  }

  .md\:mb-4 {
    margin-bottom: 4rem !important
  }

  .md\:ml-4 {
    margin-left: 4rem !important
  }

  .md\:mt-5 {
    margin-top: 5rem !important
  }

  .md\:mr-5 {
    margin-right: 5rem !important
  }

  .md\:mb-5 {
    margin-bottom: 5rem !important
  }

  .md\:ml-5 {
    margin-left: 5rem !important
  }

  .md\:mt-auto {
    margin-top: auto !important
  }

  .md\:mr-auto {
    margin-right: auto !important
  }

  .md\:mb-auto {
    margin-bottom: auto !important
  }

  .md\:ml-auto {
    margin-left: auto !important
  }

  .md\:mt-05 {
    margin-top: 0.5rem !important
  }

  .md\:mr-05 {
    margin-right: 0.5rem !important
  }

  .md\:mb-05 {
    margin-bottom: 0.5rem !important
  }

  .md\:ml-05 {
    margin-left: 0.5rem !important
  }

  .md\:-mt-1 {
    margin-top: -1rem !important
  }

  .md\:-mr-1 {
    margin-right: -1rem !important
  }

  .md\:-mb-1 {
    margin-bottom: -1rem !important
  }

  .md\:-ml-1 {
    margin-left: -1rem !important
  }

  .md\:-mt-2 {
    margin-top: -2rem !important
  }

  .md\:-mr-2 {
    margin-right: -2rem !important
  }

  .md\:-mb-2 {
    margin-bottom: -2rem !important
  }

  .md\:-ml-2 {
    margin-left: -2rem !important
  }

  .md\:-mt-3 {
    margin-top: -3rem !important
  }

  .md\:-mr-3 {
    margin-right: -3rem !important
  }

  .md\:-mb-3 {
    margin-bottom: -3rem !important
  }

  .md\:-ml-3 {
    margin-left: -3rem !important
  }

  .md\:-mt-4 {
    margin-top: -4rem !important
  }

  .md\:-mr-4 {
    margin-right: -4rem !important
  }

  .md\:-mb-4 {
    margin-bottom: -4rem !important
  }

  .md\:-ml-4 {
    margin-left: -4rem !important
  }

  .md\:-mt-5 {
    margin-top: -5rem !important
  }

  .md\:-mr-5 {
    margin-right: -5rem !important
  }

  .md\:-mb-5 {
    margin-bottom: -5rem !important
  }

  .md\:-ml-5 {
    margin-left: -5rem !important
  }

  .md\:-mt-05 {
    margin-top: -0.5rem !important
  }

  .md\:-mr-05 {
    margin-right: -0.5rem !important
  }

  .md\:-mb-05 {
    margin-bottom: -0.5rem !important
  }

  .md\:-ml-05 {
    margin-left: -0.5rem !important
  }

  .md\:p-0 {
    padding: 0 !important
  }

  .md\:p-1 {
    padding: 1rem !important
  }

  .md\:p-2 {
    padding: 2rem !important
  }

  .md\:p-3 {
    padding: 3rem !important
  }

  .md\:p-4 {
    padding: 4rem !important
  }

  .md\:p-5 {
    padding: 5rem !important
  }

  .md\:p-05 {
    padding: 0.5rem !important
  }

  .md\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .md\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .md\:py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .md\:px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }

  .md\:py-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important
  }

  .md\:px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }

  .md\:py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .md\:px-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
  }

  .md\:py-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important
  }

  .md\:px-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
  }

  .md\:py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important
  }

  .md\:px-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
  }

  .md\:py-05 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important
  }

  .md\:px-05 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important
  }

  .md\:pt-0 {
    padding-top: 0 !important
  }

  .md\:pr-0 {
    padding-right: 0 !important
  }

  .md\:pb-0 {
    padding-bottom: 0 !important
  }

  .md\:pl-0 {
    padding-left: 0 !important
  }

  .md\:pt-1 {
    padding-top: 1rem !important
  }

  .md\:pr-1 {
    padding-right: 1rem !important
  }

  .md\:pb-1 {
    padding-bottom: 1rem !important
  }

  .md\:pl-1 {
    padding-left: 1rem !important
  }

  .md\:pt-2 {
    padding-top: 2rem !important
  }

  .md\:pr-2 {
    padding-right: 2rem !important
  }

  .md\:pb-2 {
    padding-bottom: 2rem !important
  }

  .md\:pl-2 {
    padding-left: 2rem !important
  }

  .md\:pt-3 {
    padding-top: 3rem !important
  }

  .md\:pr-3 {
    padding-right: 3rem !important
  }

  .md\:pb-3 {
    padding-bottom: 3rem !important
  }

  .md\:pl-3 {
    padding-left: 3rem !important
  }

  .md\:pt-4 {
    padding-top: 4rem !important
  }

  .md\:pr-4 {
    padding-right: 4rem !important
  }

  .md\:pb-4 {
    padding-bottom: 4rem !important
  }

  .md\:pl-4 {
    padding-left: 4rem !important
  }

  .md\:pt-5 {
    padding-top: 5rem !important
  }

  .md\:pr-5 {
    padding-right: 5rem !important
  }

  .md\:pb-5 {
    padding-bottom: 5rem !important
  }

  .md\:pl-5 {
    padding-left: 5rem !important
  }

  .md\:pt-05 {
    padding-top: 0.5rem !important
  }

  .md\:pr-05 {
    padding-right: 0.5rem !important
  }

  .md\:pb-05 {
    padding-bottom: 0.5rem !important
  }

  .md\:pl-05 {
    padding-left: 0.5rem !important
  }

  .md\:w-0 {
    width: 0 !important
  }

  .md\:w-1 {
    width: 1rem !important
  }

  .md\:w-2 {
    width: 2rem !important
  }

  .md\:w-3 {
    width: 3rem !important
  }

  .md\:w-4 {
    width: 4rem !important
  }

  .md\:w-5 {
    width: 5rem !important
  }

  .md\:w-auto {
    width: auto !important
  }

  .md\:w-05 {
    width: 0.5rem !important
  }

  .md\:w-1\/2 {
    width: 50% !important
  }

  .md\:w-1\/3 {
    width: 33.333333% !important
  }

  .md\:w-2\/3 {
    width: 66.666667% !important
  }

  .md\:w-1\/4 {
    width: 25% !important
  }

  .md\:w-2\/4 {
    width: 50% !important
  }

  .md\:w-3\/4 {
    width: 75% !important
  }

  .md\:w-1\/5 {
    width: 20% !important
  }

  .md\:w-2\/5 {
    width: 40% !important
  }

  .md\:w-3\/5 {
    width: 60% !important
  }

  .md\:w-4\/5 {
    width: 80% !important
  }

  .md\:w-1\/6 {
    width: 16.666667% !important
  }

  .md\:w-2\/6 {
    width: 33.333333% !important
  }

  .md\:w-3\/6 {
    width: 50% !important
  }

  .md\:w-4\/6 {
    width: 66.666667% !important
  }

  .md\:w-5\/6 {
    width: 83.333333% !important
  }

  .md\:w-1\/12 {
    width: 8.333333% !important
  }

  .md\:w-2\/12 {
    width: 16.666667% !important
  }

  .md\:w-3\/12 {
    width: 25% !important
  }

  .md\:w-4\/12 {
    width: 33.333333% !important
  }

  .md\:w-5\/12 {
    width: 41.666667% !important
  }

  .md\:w-6\/12 {
    width: 50% !important
  }

  .md\:w-7\/12 {
    width: 58.333333% !important
  }

  .md\:w-8\/12 {
    width: 66.666667% !important
  }

  .md\:w-9\/12 {
    width: 75% !important
  }

  .md\:w-10\/12 {
    width: 83.333333% !important
  }

  .md\:w-11\/12 {
    width: 91.666667% !important
  }

  .md\:w-full {
    width: 100% !important
  }

  .md\:w-screen {
    width: 100vw !important
  }

  .md\:h-0 {
    height: 0 !important
  }

  .md\:h-1 {
    height: 1rem !important
  }

  .md\:h-2 {
    height: 2rem !important
  }

  .md\:h-3 {
    height: 3rem !important
  }

  .md\:h-4 {
    height: 4rem !important
  }

  .md\:h-5 {
    height: 5rem !important
  }

  .md\:h-auto {
    height: auto !important
  }

  .md\:h-05 {
    height: 0.5rem !important
  }

  .md\:h-full {
    height: 100% !important
  }

  .md\:h-screen {
    height: 100vh !important
  }

  .md\:min-h-0 {
    min-height: 0 !important
  }

  .md\:min-h-full {
    min-height: 100% !important
  }

  .md\:min-h-screen {
    min-height: 100vh !important
  }

  .md\:block {
    display: block !important
  }

  .md\:inline-block {
    display: inline-block !important
  }

  .md\:inline {
    display: inline !important
  }

  .md\:flex {
    display: flex !important
  }

  .md\:inline-flex {
    display: inline-flex !important
  }

  .md\:table {
    display: table !important
  }

  .md\:table-caption {
    display: table-caption !important
  }

  .md\:table-cell {
    display: table-cell !important
  }

  .md\:table-column {
    display: table-column !important
  }

  .md\:table-column-group {
    display: table-column-group !important
  }

  .md\:table-footer-group {
    display: table-footer-group !important
  }

  .md\:table-header-group {
    display: table-header-group !important
  }

  .md\:table-row-group {
    display: table-row-group !important
  }

  .md\:table-row {
    display: table-row !important
  }

  .md\:flow-root {
    display: flow-root !important
  }

  .md\:grid {
    display: grid !important
  }

  .md\:inline-grid {
    display: inline-grid !important
  }

  .md\:contents {
    display: contents !important
  }

  .md\:hidden {
    display: none !important
  }

  .md\:hover\:block:hover {
    display: block !important
  }

  .md\:hover\:inline-block:hover {
    display: inline-block !important
  }

  .md\:hover\:inline:hover {
    display: inline !important
  }

  .md\:hover\:flex:hover {
    display: flex !important
  }

  .md\:hover\:inline-flex:hover {
    display: inline-flex !important
  }

  .md\:hover\:table:hover {
    display: table !important
  }

  .md\:hover\:table-caption:hover {
    display: table-caption !important
  }

  .md\:hover\:table-cell:hover {
    display: table-cell !important
  }

  .md\:hover\:table-column:hover {
    display: table-column !important
  }

  .md\:hover\:table-column-group:hover {
    display: table-column-group !important
  }

  .md\:hover\:table-footer-group:hover {
    display: table-footer-group !important
  }

  .md\:hover\:table-header-group:hover {
    display: table-header-group !important
  }

  .md\:hover\:table-row-group:hover {
    display: table-row-group !important
  }

  .md\:hover\:table-row:hover {
    display: table-row !important
  }

  .md\:hover\:flow-root:hover {
    display: flow-root !important
  }

  .md\:hover\:grid:hover {
    display: grid !important
  }

  .md\:hover\:inline-grid:hover {
    display: inline-grid !important
  }

  .md\:hover\:contents:hover {
    display: contents !important
  }

  .md\:hover\:hidden:hover {
    display: none !important
  }

  .md\:flex-1 {
    flex: 1 1 0% !important
  }

  .md\:flex-auto {
    flex: 1 1 auto !important
  }

  .md\:flex-initial {
    flex: 0 1 auto !important
  }

  .md\:flex-none {
    flex: none !important
  }

  .md\:flex-row {
    flex-direction: row !important
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse !important
  }

  .md\:flex-col {
    flex-direction: column !important
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse !important
  }

  .md\:flex-grow-0 {
    flex-grow: 0 !important
  }

  .md\:flex-grow {
    flex-grow: 1 !important
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0 !important
  }

  .md\:flex-shrink {
    flex-shrink: 1 !important
  }

  .md\:flex-wrap {
    flex-wrap: wrap !important
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .md\:flex-no-wrap {
    flex-wrap: nowrap !important
  }

  .md\:justify-start {
    justify-content: flex-start !important
  }

  .md\:justify-end {
    justify-content: flex-end !important
  }

  .md\:justify-center {
    justify-content: center !important
  }

  .md\:justify-between {
    justify-content: space-between !important
  }

  .md\:justify-around {
    justify-content: space-around !important
  }

  .md\:justify-evenly {
    justify-content: space-evenly !important
  }

  .md\:items-start {
    align-items: flex-start !important
  }

  .md\:items-end {
    align-items: flex-end !important
  }

  .md\:items-center {
    align-items: center !important
  }

  .md\:items-baseline {
    align-items: baseline !important
  }

  .md\:items-stretch {
    align-items: stretch !important
  }

  .md\:text-left {
    text-align: left !important
  }

  .md\:text-center {
    text-align: center !important
  }

  .md\:text-right {
    text-align: right !important
  }

  .md\:text-justify {
    text-align: justify !important
  }

  .md\:overflow-auto {
    overflow: auto !important
  }

  .md\:overflow-hidden {
    overflow: hidden !important
  }

  .md\:overflow-visible {
    overflow: visible !important
  }

  .md\:overflow-scroll {
    overflow: scroll !important
  }

  .md\:overflow-x-auto {
    overflow-x: auto !important
  }

  .md\:overflow-y-auto {
    overflow-y: auto !important
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden !important
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden !important
  }

  .md\:overflow-x-visible {
    overflow-x: visible !important
  }

  .md\:overflow-y-visible {
    overflow-y: visible !important
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll !important
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll !important
  }

  .md\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important
  }

  .md\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important
  }

  .md\:font-hairline {
    font-weight: 100 !important
  }

  .md\:font-thin {
    font-weight: 200 !important
  }

  .md\:font-light {
    font-weight: 300 !important
  }

  .md\:font-normal {
    font-weight: 400 !important
  }

  .md\:font-medium {
    font-weight: 500 !important
  }

  .md\:font-semibold {
    font-weight: 600 !important
  }

  .md\:font-bold {
    font-weight: 700 !important
  }

  .md\:font-extrabold {
    font-weight: 800 !important
  }

  .md\:font-black {
    font-weight: 900 !important
  }

  .md\:hover\:font-hairline:hover {
    font-weight: 100 !important
  }

  .md\:hover\:font-thin:hover {
    font-weight: 200 !important
  }

  .md\:hover\:font-light:hover {
    font-weight: 300 !important
  }

  .md\:hover\:font-normal:hover {
    font-weight: 400 !important
  }

  .md\:hover\:font-medium:hover {
    font-weight: 500 !important
  }

  .md\:hover\:font-semibold:hover {
    font-weight: 600 !important
  }

  .md\:hover\:font-bold:hover {
    font-weight: 700 !important
  }

  .md\:hover\:font-extrabold:hover {
    font-weight: 800 !important
  }

  .md\:hover\:font-black:hover {
    font-weight: 900 !important
  }

  .md\:focus\:font-hairline:focus {
    font-weight: 100 !important
  }

  .md\:focus\:font-thin:focus {
    font-weight: 200 !important
  }

  .md\:focus\:font-light:focus {
    font-weight: 300 !important
  }

  .md\:focus\:font-normal:focus {
    font-weight: 400 !important
  }

  .md\:focus\:font-medium:focus {
    font-weight: 500 !important
  }

  .md\:focus\:font-semibold:focus {
    font-weight: 600 !important
  }

  .md\:focus\:font-bold:focus {
    font-weight: 700 !important
  }

  .md\:focus\:font-extrabold:focus {
    font-weight: 800 !important
  }

  .md\:focus\:font-black:focus {
    font-weight: 900 !important
  }

  .md\:text-xs {
    font-size: 1rem !important
  }

  .md\:text-sm {
    font-size: 1.2rem !important
  }

  .md\:text-base {
    font-size: 1.5rem !important
  }

  .md\:text-lg {
    font-size: 1.8rem !important
  }

  .md\:text-xl {
    font-size: 2.2rem !important
  }

  .md\:text-2xl {
    font-size: 2.6rem !important
  }

  .md\:text-3xl {
    font-size: 3rem !important
  }

  .md\:leading-3 {
    line-height: .75rem !important
  }

  .md\:leading-4 {
    line-height: 1rem !important
  }

  .md\:leading-5 {
    line-height: 1.25rem !important
  }

  .md\:leading-6 {
    line-height: 1.5rem !important
  }

  .md\:leading-7 {
    line-height: 1.75rem !important
  }

  .md\:leading-8 {
    line-height: 2rem !important
  }

  .md\:leading-9 {
    line-height: 2.25rem !important
  }

  .md\:leading-10 {
    line-height: 2.5rem !important
  }

  .md\:leading-none {
    line-height: 1 !important
  }

  .md\:leading-tight {
    line-height: 1.25 !important
  }

  .md\:leading-snug {
    line-height: 1.375 !important
  }

  .md\:leading-normal {
    line-height: 1.5 !important
  }

  .md\:leading-relaxed {
    line-height: 1.625 !important
  }

  .md\:leading-loose {
    line-height: 2 !important
  }

  .md\:list-none {
    list-style-type: none !important
  }

  .md\:list-disc {
    list-style-type: disc !important
  }

  .md\:list-decimal {
    list-style-type: decimal !important
  }

  .md\:opacity-0 {
    opacity: 0 !important
  }

  .md\:opacity-25 {
    opacity: 0.25 !important
  }

  .md\:opacity-50 {
    opacity: 0.5 !important
  }

  .md\:opacity-75 {
    opacity: 0.75 !important
  }

  .md\:opacity-100 {
    opacity: 1 !important
  }

  .md\:hover\:opacity-0:hover {
    opacity: 0 !important
  }

  .md\:hover\:opacity-25:hover {
    opacity: 0.25 !important
  }

  .md\:hover\:opacity-50:hover {
    opacity: 0.5 !important
  }

  .md\:hover\:opacity-75:hover {
    opacity: 0.75 !important
  }

  .md\:hover\:opacity-100:hover {
    opacity: 1 !important
  }

  .md\:focus\:opacity-0:focus {
    opacity: 0 !important
  }

  .md\:focus\:opacity-25:focus {
    opacity: 0.25 !important
  }

  .md\:focus\:opacity-50:focus {
    opacity: 0.5 !important
  }

  .md\:focus\:opacity-75:focus {
    opacity: 0.75 !important
  }

  .md\:focus\:opacity-100:focus {
    opacity: 1 !important
  }

  .md\:break-normal {
    word-wrap: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important
  }

  .md\:break-words {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important
  }

  .md\:break-all {
    word-break: break-all !important
  }

  .md\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important
  }

  .md\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important
  }

  .md\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important
  }

  .md\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important
  }

  .md\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important
  }

  .md\:text-white {
    color: #FFFFFF !important
  }

  .md\:text-black {
    color: #000000 !important
  }

  .md\:text-darkblue {
    color: #131A50 !important
  }

  .md\:text-main {
    color: #1C287F !important
  }

  .md\:text-error {
    color: #D80027 !important
  }

  .md\:text-warning {
    color: #FF8D27 !important
  }

  .md\:text-action {
    color: #00b78a !important
  }

  .md\:text-success {
    color: #03CE9C !important
  }

  .md\:text-paleYellow {
    color: #FFE665 !important
  }

  .md\:text-gray20 {
    color: #E3E4E8 !important
  }

  .md\:text-gray40 {
    color: #C5C6D3 !important
  }

  .md\:text-gray60 {
    color: #8589AD !important
  }

  .md\:text-gray80 {
    color: rgba(66, 71, 112, 1) !important
  }

  .md\:bg-white {
    background-color: #FFFFFF !important
  }

  .md\:bg-black {
    background-color: #000000 !important
  }

  .md\:bg-darkblue {
    background-color: #131A50 !important
  }

  .md\:bg-main {
    background-color: #1C287F !important
  }

  .md\:bg-error {
    background-color: #D80027 !important
  }

  .md\:bg-warning {
    background-color: #FF8D27 !important
  }

  .md\:bg-action {
    background-color: #00b78a !important
  }

  .md\:bg-success {
    background-color: #03CE9C !important
  }

  .md\:bg-paleYellow {
    background-color: #FFE665 !important
  }

  .md\:bg-gray20 {
    background-color: #E3E4E8 !important
  }

  .md\:bg-gray40 {
    background-color: #C5C6D3 !important
  }

  .md\:bg-gray60 {
    background-color: #8589AD !important
  }

  .md\:bg-gray80 {
    background-color: rgba(66, 71, 112, 1) !important
  }

  .md\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important
  }

  .md\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important
  }

  .md\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important
  }

  .md\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important
  }

  .md\:underline {
    text-decoration: underline !important
  }

  .md\:line-through {
    text-decoration: line-through !important
  }

  .md\:no-underline {
    text-decoration: none !important
  }

  .md\:hover\:underline:hover {
    text-decoration: underline !important
  }

  .md\:hover\:line-through:hover {
    text-decoration: line-through !important
  }

  .md\:hover\:no-underline:hover {
    text-decoration: none !important
  }

  .md\:focus\:underline:focus {
    text-decoration: underline !important
  }

  .md\:focus\:line-through:focus {
    text-decoration: line-through !important
  }

  .md\:focus\:no-underline:focus {
    text-decoration: none !important
  }

  .md\:grid-flow-row {
    grid-auto-flow: row !important
  }

  .md\:grid-flow-col {
    grid-auto-flow: column !important
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense !important
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense !important
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important
  }

  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important
  }

  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important
  }

  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important
  }

  .md\:grid-rows-none {
    grid-template-rows: none !important
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important
  }

  .md\:grid-cols-none {
    grid-template-columns: none !important
  }

  .md\:auto-rows-auto {
    grid-auto-rows: auto !important
  }

  .md\:auto-rows-min {
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important
  }

  .md\:auto-rows-max {
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important
  }

  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important
  }

  .md\:auto-cols-auto {
    grid-auto-columns: auto !important
  }

  .md\:auto-cols-min {
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important
  }

  .md\:auto-cols-max {
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important
  }

  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important
  }

  .md\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important
  }

  .md\:gap-1 {
    grid-gap: 1rem !important;
    gap: 1rem !important
  }

  .md\:gap-2 {
    grid-gap: 2rem !important;
    gap: 2rem !important
  }

  .md\:gap-3 {
    grid-gap: 3rem !important;
    gap: 3rem !important
  }

  .md\:gap-4 {
    grid-gap: 4rem !important;
    gap: 4rem !important
  }

  .md\:gap-5 {
    grid-gap: 5rem !important;
    gap: 5rem !important
  }

  .md\:gap-05 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important
  }

  .md\:col-gap-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important
  }

  .md\:col-gap-1 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important
  }

  .md\:col-gap-2 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important
  }

  .md\:col-gap-3 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important
  }

  .md\:col-gap-4 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important
  }

  .md\:col-gap-5 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important
  }

  .md\:col-gap-05 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important
  }

  .md\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important
  }

  .md\:gap-x-1 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important
  }

  .md\:gap-x-2 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important
  }

  .md\:gap-x-3 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important
  }

  .md\:gap-x-4 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important
  }

  .md\:gap-x-5 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important
  }

  .md\:gap-x-05 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important
  }

  .md\:row-gap-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important
  }

  .md\:row-gap-1 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important
  }

  .md\:row-gap-2 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important
  }

  .md\:row-gap-3 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important
  }

  .md\:row-gap-4 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important
  }

  .md\:row-gap-5 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important
  }

  .md\:row-gap-05 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important
  }

  .md\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important
  }

  .md\:gap-y-1 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important
  }

  .md\:gap-y-2 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important
  }

  .md\:gap-y-3 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important
  }

  .md\:gap-y-4 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important
  }

  .md\:gap-y-5 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important
  }

  .md\:gap-y-05 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important
  }
}

@media (min-width: 1024px) {
  .lg\:static {
    position: static !important
  }

  .lg\:fixed {
    position: fixed !important
  }

  .lg\:absolute {
    position: absolute !important
  }

  .lg\:relative {
    position: relative !important
  }

  .lg\:sticky {
    position: sticky !important
  }

  .lg\:m-0 {
    margin: 0 !important
  }

  .lg\:m-1 {
    margin: 1rem !important
  }

  .lg\:m-2 {
    margin: 2rem !important
  }

  .lg\:m-3 {
    margin: 3rem !important
  }

  .lg\:m-4 {
    margin: 4rem !important
  }

  .lg\:m-5 {
    margin: 5rem !important
  }

  .lg\:m-auto {
    margin: auto !important
  }

  .lg\:m-05 {
    margin: 0.5rem !important
  }

  .lg\:-m-1 {
    margin: -1rem !important
  }

  .lg\:-m-2 {
    margin: -2rem !important
  }

  .lg\:-m-3 {
    margin: -3rem !important
  }

  .lg\:-m-4 {
    margin: -4rem !important
  }

  .lg\:-m-5 {
    margin: -5rem !important
  }

  .lg\:-m-05 {
    margin: -0.5rem !important
  }

  .lg\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .lg\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .lg\:my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .lg\:mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
  }

  .lg\:my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important
  }

  .lg\:mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
  }

  .lg\:my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .lg\:mx-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
  }

  .lg\:my-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important
  }

  .lg\:mx-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
  }

  .lg\:my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important
  }

  .lg\:mx-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
  }

  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .lg\:my-05 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important
  }

  .lg\:mx-05 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important
  }

  .lg\:-my-1 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important
  }

  .lg\:-mx-1 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
  }

  .lg\:-my-2 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important
  }

  .lg\:-mx-2 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
  }

  .lg\:-my-3 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important
  }

  .lg\:-mx-3 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
  }

  .lg\:-my-4 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important
  }

  .lg\:-mx-4 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
  }

  .lg\:-my-5 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important
  }

  .lg\:-mx-5 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
  }

  .lg\:-my-05 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important
  }

  .lg\:-mx-05 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important
  }

  .lg\:mt-0 {
    margin-top: 0 !important
  }

  .lg\:mr-0 {
    margin-right: 0 !important
  }

  .lg\:mb-0 {
    margin-bottom: 0 !important
  }

  .lg\:ml-0 {
    margin-left: 0 !important
  }

  .lg\:mt-1 {
    margin-top: 1rem !important
  }

  .lg\:mr-1 {
    margin-right: 1rem !important
  }

  .lg\:mb-1 {
    margin-bottom: 1rem !important
  }

  .lg\:ml-1 {
    margin-left: 1rem !important
  }

  .lg\:mt-2 {
    margin-top: 2rem !important
  }

  .lg\:mr-2 {
    margin-right: 2rem !important
  }

  .lg\:mb-2 {
    margin-bottom: 2rem !important
  }

  .lg\:ml-2 {
    margin-left: 2rem !important
  }

  .lg\:mt-3 {
    margin-top: 3rem !important
  }

  .lg\:mr-3 {
    margin-right: 3rem !important
  }

  .lg\:mb-3 {
    margin-bottom: 3rem !important
  }

  .lg\:ml-3 {
    margin-left: 3rem !important
  }

  .lg\:mt-4 {
    margin-top: 4rem !important
  }

  .lg\:mr-4 {
    margin-right: 4rem !important
  }

  .lg\:mb-4 {
    margin-bottom: 4rem !important
  }

  .lg\:ml-4 {
    margin-left: 4rem !important
  }

  .lg\:mt-5 {
    margin-top: 5rem !important
  }

  .lg\:mr-5 {
    margin-right: 5rem !important
  }

  .lg\:mb-5 {
    margin-bottom: 5rem !important
  }

  .lg\:ml-5 {
    margin-left: 5rem !important
  }

  .lg\:mt-auto {
    margin-top: auto !important
  }

  .lg\:mr-auto {
    margin-right: auto !important
  }

  .lg\:mb-auto {
    margin-bottom: auto !important
  }

  .lg\:ml-auto {
    margin-left: auto !important
  }

  .lg\:mt-05 {
    margin-top: 0.5rem !important
  }

  .lg\:mr-05 {
    margin-right: 0.5rem !important
  }

  .lg\:mb-05 {
    margin-bottom: 0.5rem !important
  }

  .lg\:ml-05 {
    margin-left: 0.5rem !important
  }

  .lg\:-mt-1 {
    margin-top: -1rem !important
  }

  .lg\:-mr-1 {
    margin-right: -1rem !important
  }

  .lg\:-mb-1 {
    margin-bottom: -1rem !important
  }

  .lg\:-ml-1 {
    margin-left: -1rem !important
  }

  .lg\:-mt-2 {
    margin-top: -2rem !important
  }

  .lg\:-mr-2 {
    margin-right: -2rem !important
  }

  .lg\:-mb-2 {
    margin-bottom: -2rem !important
  }

  .lg\:-ml-2 {
    margin-left: -2rem !important
  }

  .lg\:-mt-3 {
    margin-top: -3rem !important
  }

  .lg\:-mr-3 {
    margin-right: -3rem !important
  }

  .lg\:-mb-3 {
    margin-bottom: -3rem !important
  }

  .lg\:-ml-3 {
    margin-left: -3rem !important
  }

  .lg\:-mt-4 {
    margin-top: -4rem !important
  }

  .lg\:-mr-4 {
    margin-right: -4rem !important
  }

  .lg\:-mb-4 {
    margin-bottom: -4rem !important
  }

  .lg\:-ml-4 {
    margin-left: -4rem !important
  }

  .lg\:-mt-5 {
    margin-top: -5rem !important
  }

  .lg\:-mr-5 {
    margin-right: -5rem !important
  }

  .lg\:-mb-5 {
    margin-bottom: -5rem !important
  }

  .lg\:-ml-5 {
    margin-left: -5rem !important
  }

  .lg\:-mt-05 {
    margin-top: -0.5rem !important
  }

  .lg\:-mr-05 {
    margin-right: -0.5rem !important
  }

  .lg\:-mb-05 {
    margin-bottom: -0.5rem !important
  }

  .lg\:-ml-05 {
    margin-left: -0.5rem !important
  }

  .lg\:p-0 {
    padding: 0 !important
  }

  .lg\:p-1 {
    padding: 1rem !important
  }

  .lg\:p-2 {
    padding: 2rem !important
  }

  .lg\:p-3 {
    padding: 3rem !important
  }

  .lg\:p-4 {
    padding: 4rem !important
  }

  .lg\:p-5 {
    padding: 5rem !important
  }

  .lg\:p-05 {
    padding: 0.5rem !important
  }

  .lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .lg\:py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .lg\:px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }

  .lg\:py-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important
  }

  .lg\:px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }

  .lg\:py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .lg\:px-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
  }

  .lg\:py-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important
  }

  .lg\:px-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
  }

  .lg\:py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important
  }

  .lg\:px-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
  }

  .lg\:py-05 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important
  }

  .lg\:px-05 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important
  }

  .lg\:pt-0 {
    padding-top: 0 !important
  }

  .lg\:pr-0 {
    padding-right: 0 !important
  }

  .lg\:pb-0 {
    padding-bottom: 0 !important
  }

  .lg\:pl-0 {
    padding-left: 0 !important
  }

  .lg\:pt-1 {
    padding-top: 1rem !important
  }

  .lg\:pr-1 {
    padding-right: 1rem !important
  }

  .lg\:pb-1 {
    padding-bottom: 1rem !important
  }

  .lg\:pl-1 {
    padding-left: 1rem !important
  }

  .lg\:pt-2 {
    padding-top: 2rem !important
  }

  .lg\:pr-2 {
    padding-right: 2rem !important
  }

  .lg\:pb-2 {
    padding-bottom: 2rem !important
  }

  .lg\:pl-2 {
    padding-left: 2rem !important
  }

  .lg\:pt-3 {
    padding-top: 3rem !important
  }

  .lg\:pr-3 {
    padding-right: 3rem !important
  }

  .lg\:pb-3 {
    padding-bottom: 3rem !important
  }

  .lg\:pl-3 {
    padding-left: 3rem !important
  }

  .lg\:pt-4 {
    padding-top: 4rem !important
  }

  .lg\:pr-4 {
    padding-right: 4rem !important
  }

  .lg\:pb-4 {
    padding-bottom: 4rem !important
  }

  .lg\:pl-4 {
    padding-left: 4rem !important
  }

  .lg\:pt-5 {
    padding-top: 5rem !important
  }

  .lg\:pr-5 {
    padding-right: 5rem !important
  }

  .lg\:pb-5 {
    padding-bottom: 5rem !important
  }

  .lg\:pl-5 {
    padding-left: 5rem !important
  }

  .lg\:pt-05 {
    padding-top: 0.5rem !important
  }

  .lg\:pr-05 {
    padding-right: 0.5rem !important
  }

  .lg\:pb-05 {
    padding-bottom: 0.5rem !important
  }

  .lg\:pl-05 {
    padding-left: 0.5rem !important
  }

  .lg\:w-0 {
    width: 0 !important
  }

  .lg\:w-1 {
    width: 1rem !important
  }

  .lg\:w-2 {
    width: 2rem !important
  }

  .lg\:w-3 {
    width: 3rem !important
  }

  .lg\:w-4 {
    width: 4rem !important
  }

  .lg\:w-5 {
    width: 5rem !important
  }

  .lg\:w-auto {
    width: auto !important
  }

  .lg\:w-05 {
    width: 0.5rem !important
  }

  .lg\:w-1\/2 {
    width: 50% !important
  }

  .lg\:w-1\/3 {
    width: 33.333333% !important
  }

  .lg\:w-2\/3 {
    width: 66.666667% !important
  }

  .lg\:w-1\/4 {
    width: 25% !important
  }

  .lg\:w-2\/4 {
    width: 50% !important
  }

  .lg\:w-3\/4 {
    width: 75% !important
  }

  .lg\:w-1\/5 {
    width: 20% !important
  }

  .lg\:w-2\/5 {
    width: 40% !important
  }

  .lg\:w-3\/5 {
    width: 60% !important
  }

  .lg\:w-4\/5 {
    width: 80% !important
  }

  .lg\:w-1\/6 {
    width: 16.666667% !important
  }

  .lg\:w-2\/6 {
    width: 33.333333% !important
  }

  .lg\:w-3\/6 {
    width: 50% !important
  }

  .lg\:w-4\/6 {
    width: 66.666667% !important
  }

  .lg\:w-5\/6 {
    width: 83.333333% !important
  }

  .lg\:w-1\/12 {
    width: 8.333333% !important
  }

  .lg\:w-2\/12 {
    width: 16.666667% !important
  }

  .lg\:w-3\/12 {
    width: 25% !important
  }

  .lg\:w-4\/12 {
    width: 33.333333% !important
  }

  .lg\:w-5\/12 {
    width: 41.666667% !important
  }

  .lg\:w-6\/12 {
    width: 50% !important
  }

  .lg\:w-7\/12 {
    width: 58.333333% !important
  }

  .lg\:w-8\/12 {
    width: 66.666667% !important
  }

  .lg\:w-9\/12 {
    width: 75% !important
  }

  .lg\:w-10\/12 {
    width: 83.333333% !important
  }

  .lg\:w-11\/12 {
    width: 91.666667% !important
  }

  .lg\:w-full {
    width: 100% !important
  }

  .lg\:w-screen {
    width: 100vw !important
  }

  .lg\:h-0 {
    height: 0 !important
  }

  .lg\:h-1 {
    height: 1rem !important
  }

  .lg\:h-2 {
    height: 2rem !important
  }

  .lg\:h-3 {
    height: 3rem !important
  }

  .lg\:h-4 {
    height: 4rem !important
  }

  .lg\:h-5 {
    height: 5rem !important
  }

  .lg\:h-auto {
    height: auto !important
  }

  .lg\:h-05 {
    height: 0.5rem !important
  }

  .lg\:h-full {
    height: 100% !important
  }

  .lg\:h-screen {
    height: 100vh !important
  }

  .lg\:min-h-0 {
    min-height: 0 !important
  }

  .lg\:min-h-full {
    min-height: 100% !important
  }

  .lg\:min-h-screen {
    min-height: 100vh !important
  }

  .lg\:block {
    display: block !important
  }

  .lg\:inline-block {
    display: inline-block !important
  }

  .lg\:inline {
    display: inline !important
  }

  .lg\:flex {
    display: flex !important
  }

  .lg\:inline-flex {
    display: inline-flex !important
  }

  .lg\:table {
    display: table !important
  }

  .lg\:table-caption {
    display: table-caption !important
  }

  .lg\:table-cell {
    display: table-cell !important
  }

  .lg\:table-column {
    display: table-column !important
  }

  .lg\:table-column-group {
    display: table-column-group !important
  }

  .lg\:table-footer-group {
    display: table-footer-group !important
  }

  .lg\:table-header-group {
    display: table-header-group !important
  }

  .lg\:table-row-group {
    display: table-row-group !important
  }

  .lg\:table-row {
    display: table-row !important
  }

  .lg\:flow-root {
    display: flow-root !important
  }

  .lg\:grid {
    display: grid !important
  }

  .lg\:inline-grid {
    display: inline-grid !important
  }

  .lg\:contents {
    display: contents !important
  }

  .lg\:hidden {
    display: none !important
  }

  .lg\:hover\:block:hover {
    display: block !important
  }

  .lg\:hover\:inline-block:hover {
    display: inline-block !important
  }

  .lg\:hover\:inline:hover {
    display: inline !important
  }

  .lg\:hover\:flex:hover {
    display: flex !important
  }

  .lg\:hover\:inline-flex:hover {
    display: inline-flex !important
  }

  .lg\:hover\:table:hover {
    display: table !important
  }

  .lg\:hover\:table-caption:hover {
    display: table-caption !important
  }

  .lg\:hover\:table-cell:hover {
    display: table-cell !important
  }

  .lg\:hover\:table-column:hover {
    display: table-column !important
  }

  .lg\:hover\:table-column-group:hover {
    display: table-column-group !important
  }

  .lg\:hover\:table-footer-group:hover {
    display: table-footer-group !important
  }

  .lg\:hover\:table-header-group:hover {
    display: table-header-group !important
  }

  .lg\:hover\:table-row-group:hover {
    display: table-row-group !important
  }

  .lg\:hover\:table-row:hover {
    display: table-row !important
  }

  .lg\:hover\:flow-root:hover {
    display: flow-root !important
  }

  .lg\:hover\:grid:hover {
    display: grid !important
  }

  .lg\:hover\:inline-grid:hover {
    display: inline-grid !important
  }

  .lg\:hover\:contents:hover {
    display: contents !important
  }

  .lg\:hover\:hidden:hover {
    display: none !important
  }

  .lg\:flex-1 {
    flex: 1 1 0% !important
  }

  .lg\:flex-auto {
    flex: 1 1 auto !important
  }

  .lg\:flex-initial {
    flex: 0 1 auto !important
  }

  .lg\:flex-none {
    flex: none !important
  }

  .lg\:flex-row {
    flex-direction: row !important
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important
  }

  .lg\:flex-col {
    flex-direction: column !important
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse !important
  }

  .lg\:flex-grow-0 {
    flex-grow: 0 !important
  }

  .lg\:flex-grow {
    flex-grow: 1 !important
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important
  }

  .lg\:flex-shrink {
    flex-shrink: 1 !important
  }

  .lg\:flex-wrap {
    flex-wrap: wrap !important
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .lg\:flex-no-wrap {
    flex-wrap: nowrap !important
  }

  .lg\:justify-start {
    justify-content: flex-start !important
  }

  .lg\:justify-end {
    justify-content: flex-end !important
  }

  .lg\:justify-center {
    justify-content: center !important
  }

  .lg\:justify-between {
    justify-content: space-between !important
  }

  .lg\:justify-around {
    justify-content: space-around !important
  }

  .lg\:justify-evenly {
    justify-content: space-evenly !important
  }

  .lg\:items-start {
    align-items: flex-start !important
  }

  .lg\:items-end {
    align-items: flex-end !important
  }

  .lg\:items-center {
    align-items: center !important
  }

  .lg\:items-baseline {
    align-items: baseline !important
  }

  .lg\:items-stretch {
    align-items: stretch !important
  }

  .lg\:text-left {
    text-align: left !important
  }

  .lg\:text-center {
    text-align: center !important
  }

  .lg\:text-right {
    text-align: right !important
  }

  .lg\:text-justify {
    text-align: justify !important
  }

  .lg\:overflow-auto {
    overflow: auto !important
  }

  .lg\:overflow-hidden {
    overflow: hidden !important
  }

  .lg\:overflow-visible {
    overflow: visible !important
  }

  .lg\:overflow-scroll {
    overflow: scroll !important
  }

  .lg\:overflow-x-auto {
    overflow-x: auto !important
  }

  .lg\:overflow-y-auto {
    overflow-y: auto !important
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden !important
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden !important
  }

  .lg\:overflow-x-visible {
    overflow-x: visible !important
  }

  .lg\:overflow-y-visible {
    overflow-y: visible !important
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll !important
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll !important
  }

  .lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important
  }

  .lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important
  }

  .lg\:font-hairline {
    font-weight: 100 !important
  }

  .lg\:font-thin {
    font-weight: 200 !important
  }

  .lg\:font-light {
    font-weight: 300 !important
  }

  .lg\:font-normal {
    font-weight: 400 !important
  }

  .lg\:font-medium {
    font-weight: 500 !important
  }

  .lg\:font-semibold {
    font-weight: 600 !important
  }

  .lg\:font-bold {
    font-weight: 700 !important
  }

  .lg\:font-extrabold {
    font-weight: 800 !important
  }

  .lg\:font-black {
    font-weight: 900 !important
  }

  .lg\:hover\:font-hairline:hover {
    font-weight: 100 !important
  }

  .lg\:hover\:font-thin:hover {
    font-weight: 200 !important
  }

  .lg\:hover\:font-light:hover {
    font-weight: 300 !important
  }

  .lg\:hover\:font-normal:hover {
    font-weight: 400 !important
  }

  .lg\:hover\:font-medium:hover {
    font-weight: 500 !important
  }

  .lg\:hover\:font-semibold:hover {
    font-weight: 600 !important
  }

  .lg\:hover\:font-bold:hover {
    font-weight: 700 !important
  }

  .lg\:hover\:font-extrabold:hover {
    font-weight: 800 !important
  }

  .lg\:hover\:font-black:hover {
    font-weight: 900 !important
  }

  .lg\:focus\:font-hairline:focus {
    font-weight: 100 !important
  }

  .lg\:focus\:font-thin:focus {
    font-weight: 200 !important
  }

  .lg\:focus\:font-light:focus {
    font-weight: 300 !important
  }

  .lg\:focus\:font-normal:focus {
    font-weight: 400 !important
  }

  .lg\:focus\:font-medium:focus {
    font-weight: 500 !important
  }

  .lg\:focus\:font-semibold:focus {
    font-weight: 600 !important
  }

  .lg\:focus\:font-bold:focus {
    font-weight: 700 !important
  }

  .lg\:focus\:font-extrabold:focus {
    font-weight: 800 !important
  }

  .lg\:focus\:font-black:focus {
    font-weight: 900 !important
  }

  .lg\:text-xs {
    font-size: 1rem !important
  }

  .lg\:text-sm {
    font-size: 1.2rem !important
  }

  .lg\:text-base {
    font-size: 1.5rem !important
  }

  .lg\:text-lg {
    font-size: 1.8rem !important
  }

  .lg\:text-xl {
    font-size: 2.2rem !important
  }

  .lg\:text-2xl {
    font-size: 2.6rem !important
  }

  .lg\:text-3xl {
    font-size: 3rem !important
  }

  .lg\:leading-3 {
    line-height: .75rem !important
  }

  .lg\:leading-4 {
    line-height: 1rem !important
  }

  .lg\:leading-5 {
    line-height: 1.25rem !important
  }

  .lg\:leading-6 {
    line-height: 1.5rem !important
  }

  .lg\:leading-7 {
    line-height: 1.75rem !important
  }

  .lg\:leading-8 {
    line-height: 2rem !important
  }

  .lg\:leading-9 {
    line-height: 2.25rem !important
  }

  .lg\:leading-10 {
    line-height: 2.5rem !important
  }

  .lg\:leading-none {
    line-height: 1 !important
  }

  .lg\:leading-tight {
    line-height: 1.25 !important
  }

  .lg\:leading-snug {
    line-height: 1.375 !important
  }

  .lg\:leading-normal {
    line-height: 1.5 !important
  }

  .lg\:leading-relaxed {
    line-height: 1.625 !important
  }

  .lg\:leading-loose {
    line-height: 2 !important
  }

  .lg\:list-none {
    list-style-type: none !important
  }

  .lg\:list-disc {
    list-style-type: disc !important
  }

  .lg\:list-decimal {
    list-style-type: decimal !important
  }

  .lg\:opacity-0 {
    opacity: 0 !important
  }

  .lg\:opacity-25 {
    opacity: 0.25 !important
  }

  .lg\:opacity-50 {
    opacity: 0.5 !important
  }

  .lg\:opacity-75 {
    opacity: 0.75 !important
  }

  .lg\:opacity-100 {
    opacity: 1 !important
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0 !important
  }

  .lg\:hover\:opacity-25:hover {
    opacity: 0.25 !important
  }

  .lg\:hover\:opacity-50:hover {
    opacity: 0.5 !important
  }

  .lg\:hover\:opacity-75:hover {
    opacity: 0.75 !important
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1 !important
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0 !important
  }

  .lg\:focus\:opacity-25:focus {
    opacity: 0.25 !important
  }

  .lg\:focus\:opacity-50:focus {
    opacity: 0.5 !important
  }

  .lg\:focus\:opacity-75:focus {
    opacity: 0.75 !important
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1 !important
  }

  .lg\:break-normal {
    word-wrap: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important
  }

  .lg\:break-words {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important
  }

  .lg\:break-all {
    word-break: break-all !important
  }

  .lg\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important
  }

  .lg\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important
  }

  .lg\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important
  }

  .lg\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important
  }

  .lg\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important
  }

  .lg\:text-white {
    color: #FFFFFF !important
  }

  .lg\:text-black {
    color: #000000 !important
  }

  .lg\:text-darkblue {
    color: #131A50 !important
  }

  .lg\:text-main {
    color: #1C287F !important
  }

  .lg\:text-error {
    color: #D80027 !important
  }

  .lg\:text-warning {
    color: #FF8D27 !important
  }

  .lg\:text-action {
    color: #00b78a !important
  }

  .lg\:text-success {
    color: #03CE9C !important
  }

  .lg\:text-paleYellow {
    color: #FFE665 !important
  }

  .lg\:text-gray20 {
    color: #E3E4E8 !important
  }

  .lg\:text-gray40 {
    color: #C5C6D3 !important
  }

  .lg\:text-gray60 {
    color: #8589AD !important
  }

  .lg\:text-gray80 {
    color: rgba(66, 71, 112, 1) !important
  }

  .lg\:bg-white {
    background-color: #FFFFFF !important
  }

  .lg\:bg-black {
    background-color: #000000 !important
  }

  .lg\:bg-darkblue {
    background-color: #131A50 !important
  }

  .lg\:bg-main {
    background-color: #1C287F !important
  }

  .lg\:bg-error {
    background-color: #D80027 !important
  }

  .lg\:bg-warning {
    background-color: #FF8D27 !important
  }

  .lg\:bg-action {
    background-color: #00b78a !important
  }

  .lg\:bg-success {
    background-color: #03CE9C !important
  }

  .lg\:bg-paleYellow {
    background-color: #FFE665 !important
  }

  .lg\:bg-gray20 {
    background-color: #E3E4E8 !important
  }

  .lg\:bg-gray40 {
    background-color: #C5C6D3 !important
  }

  .lg\:bg-gray60 {
    background-color: #8589AD !important
  }

  .lg\:bg-gray80 {
    background-color: rgba(66, 71, 112, 1) !important
  }

  .lg\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important
  }

  .lg\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important
  }

  .lg\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important
  }

  .lg\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important
  }

  .lg\:underline {
    text-decoration: underline !important
  }

  .lg\:line-through {
    text-decoration: line-through !important
  }

  .lg\:no-underline {
    text-decoration: none !important
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline !important
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through !important
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none !important
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline !important
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through !important
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none !important
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row !important
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column !important
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense !important
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense !important
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important
  }

  .lg\:grid-rows-none {
    grid-template-rows: none !important
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important
  }

  .lg\:grid-cols-none {
    grid-template-columns: none !important
  }

  .lg\:auto-rows-auto {
    grid-auto-rows: auto !important
  }

  .lg\:auto-rows-min {
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important
  }

  .lg\:auto-rows-max {
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important
  }

  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important
  }

  .lg\:auto-cols-auto {
    grid-auto-columns: auto !important
  }

  .lg\:auto-cols-min {
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important
  }

  .lg\:auto-cols-max {
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important
  }

  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important
  }

  .lg\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important
  }

  .lg\:gap-1 {
    grid-gap: 1rem !important;
    gap: 1rem !important
  }

  .lg\:gap-2 {
    grid-gap: 2rem !important;
    gap: 2rem !important
  }

  .lg\:gap-3 {
    grid-gap: 3rem !important;
    gap: 3rem !important
  }

  .lg\:gap-4 {
    grid-gap: 4rem !important;
    gap: 4rem !important
  }

  .lg\:gap-5 {
    grid-gap: 5rem !important;
    gap: 5rem !important
  }

  .lg\:gap-05 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important
  }

  .lg\:col-gap-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important
  }

  .lg\:col-gap-1 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important
  }

  .lg\:col-gap-2 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important
  }

  .lg\:col-gap-3 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important
  }

  .lg\:col-gap-4 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important
  }

  .lg\:col-gap-5 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important
  }

  .lg\:col-gap-05 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important
  }

  .lg\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important
  }

  .lg\:gap-x-1 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important
  }

  .lg\:gap-x-2 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important
  }

  .lg\:gap-x-3 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important
  }

  .lg\:gap-x-4 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important
  }

  .lg\:gap-x-5 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important
  }

  .lg\:gap-x-05 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important
  }

  .lg\:row-gap-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important
  }

  .lg\:row-gap-1 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important
  }

  .lg\:row-gap-2 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important
  }

  .lg\:row-gap-3 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important
  }

  .lg\:row-gap-4 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important
  }

  .lg\:row-gap-5 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important
  }

  .lg\:row-gap-05 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important
  }

  .lg\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important
  }

  .lg\:gap-y-1 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important
  }

  .lg\:gap-y-2 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important
  }

  .lg\:gap-y-3 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important
  }

  .lg\:gap-y-4 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important
  }

  .lg\:gap-y-5 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important
  }

  .lg\:gap-y-05 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important
  }
}

@media (min-width: 1280px) {
  .xl\:static {
    position: static !important
  }

  .xl\:fixed {
    position: fixed !important
  }

  .xl\:absolute {
    position: absolute !important
  }

  .xl\:relative {
    position: relative !important
  }

  .xl\:sticky {
    position: sticky !important
  }

  .xl\:m-0 {
    margin: 0 !important
  }

  .xl\:m-1 {
    margin: 1rem !important
  }

  .xl\:m-2 {
    margin: 2rem !important
  }

  .xl\:m-3 {
    margin: 3rem !important
  }

  .xl\:m-4 {
    margin: 4rem !important
  }

  .xl\:m-5 {
    margin: 5rem !important
  }

  .xl\:m-auto {
    margin: auto !important
  }

  .xl\:m-05 {
    margin: 0.5rem !important
  }

  .xl\:-m-1 {
    margin: -1rem !important
  }

  .xl\:-m-2 {
    margin: -2rem !important
  }

  .xl\:-m-3 {
    margin: -3rem !important
  }

  .xl\:-m-4 {
    margin: -4rem !important
  }

  .xl\:-m-5 {
    margin: -5rem !important
  }

  .xl\:-m-05 {
    margin: -0.5rem !important
  }

  .xl\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .xl\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }

  .xl\:my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .xl\:mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
  }

  .xl\:my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important
  }

  .xl\:mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
  }

  .xl\:my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .xl\:mx-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
  }

  .xl\:my-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important
  }

  .xl\:mx-4 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
  }

  .xl\:my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important
  }

  .xl\:mx-5 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
  }

  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }

  .xl\:my-05 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important
  }

  .xl\:mx-05 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important
  }

  .xl\:-my-1 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important
  }

  .xl\:-mx-1 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
  }

  .xl\:-my-2 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important
  }

  .xl\:-mx-2 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
  }

  .xl\:-my-3 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important
  }

  .xl\:-mx-3 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
  }

  .xl\:-my-4 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important
  }

  .xl\:-mx-4 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
  }

  .xl\:-my-5 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important
  }

  .xl\:-mx-5 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
  }

  .xl\:-my-05 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important
  }

  .xl\:-mx-05 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important
  }

  .xl\:mt-0 {
    margin-top: 0 !important
  }

  .xl\:mr-0 {
    margin-right: 0 !important
  }

  .xl\:mb-0 {
    margin-bottom: 0 !important
  }

  .xl\:ml-0 {
    margin-left: 0 !important
  }

  .xl\:mt-1 {
    margin-top: 1rem !important
  }

  .xl\:mr-1 {
    margin-right: 1rem !important
  }

  .xl\:mb-1 {
    margin-bottom: 1rem !important
  }

  .xl\:ml-1 {
    margin-left: 1rem !important
  }

  .xl\:mt-2 {
    margin-top: 2rem !important
  }

  .xl\:mr-2 {
    margin-right: 2rem !important
  }

  .xl\:mb-2 {
    margin-bottom: 2rem !important
  }

  .xl\:ml-2 {
    margin-left: 2rem !important
  }

  .xl\:mt-3 {
    margin-top: 3rem !important
  }

  .xl\:mr-3 {
    margin-right: 3rem !important
  }

  .xl\:mb-3 {
    margin-bottom: 3rem !important
  }

  .xl\:ml-3 {
    margin-left: 3rem !important
  }

  .xl\:mt-4 {
    margin-top: 4rem !important
  }

  .xl\:mr-4 {
    margin-right: 4rem !important
  }

  .xl\:mb-4 {
    margin-bottom: 4rem !important
  }

  .xl\:ml-4 {
    margin-left: 4rem !important
  }

  .xl\:mt-5 {
    margin-top: 5rem !important
  }

  .xl\:mr-5 {
    margin-right: 5rem !important
  }

  .xl\:mb-5 {
    margin-bottom: 5rem !important
  }

  .xl\:ml-5 {
    margin-left: 5rem !important
  }

  .xl\:mt-auto {
    margin-top: auto !important
  }

  .xl\:mr-auto {
    margin-right: auto !important
  }

  .xl\:mb-auto {
    margin-bottom: auto !important
  }

  .xl\:ml-auto {
    margin-left: auto !important
  }

  .xl\:mt-05 {
    margin-top: 0.5rem !important
  }

  .xl\:mr-05 {
    margin-right: 0.5rem !important
  }

  .xl\:mb-05 {
    margin-bottom: 0.5rem !important
  }

  .xl\:ml-05 {
    margin-left: 0.5rem !important
  }

  .xl\:-mt-1 {
    margin-top: -1rem !important
  }

  .xl\:-mr-1 {
    margin-right: -1rem !important
  }

  .xl\:-mb-1 {
    margin-bottom: -1rem !important
  }

  .xl\:-ml-1 {
    margin-left: -1rem !important
  }

  .xl\:-mt-2 {
    margin-top: -2rem !important
  }

  .xl\:-mr-2 {
    margin-right: -2rem !important
  }

  .xl\:-mb-2 {
    margin-bottom: -2rem !important
  }

  .xl\:-ml-2 {
    margin-left: -2rem !important
  }

  .xl\:-mt-3 {
    margin-top: -3rem !important
  }

  .xl\:-mr-3 {
    margin-right: -3rem !important
  }

  .xl\:-mb-3 {
    margin-bottom: -3rem !important
  }

  .xl\:-ml-3 {
    margin-left: -3rem !important
  }

  .xl\:-mt-4 {
    margin-top: -4rem !important
  }

  .xl\:-mr-4 {
    margin-right: -4rem !important
  }

  .xl\:-mb-4 {
    margin-bottom: -4rem !important
  }

  .xl\:-ml-4 {
    margin-left: -4rem !important
  }

  .xl\:-mt-5 {
    margin-top: -5rem !important
  }

  .xl\:-mr-5 {
    margin-right: -5rem !important
  }

  .xl\:-mb-5 {
    margin-bottom: -5rem !important
  }

  .xl\:-ml-5 {
    margin-left: -5rem !important
  }

  .xl\:-mt-05 {
    margin-top: -0.5rem !important
  }

  .xl\:-mr-05 {
    margin-right: -0.5rem !important
  }

  .xl\:-mb-05 {
    margin-bottom: -0.5rem !important
  }

  .xl\:-ml-05 {
    margin-left: -0.5rem !important
  }

  .xl\:p-0 {
    padding: 0 !important
  }

  .xl\:p-1 {
    padding: 1rem !important
  }

  .xl\:p-2 {
    padding: 2rem !important
  }

  .xl\:p-3 {
    padding: 3rem !important
  }

  .xl\:p-4 {
    padding: 4rem !important
  }

  .xl\:p-5 {
    padding: 5rem !important
  }

  .xl\:p-05 {
    padding: 0.5rem !important
  }

  .xl\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .xl\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .xl\:py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .xl\:px-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }

  .xl\:py-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important
  }

  .xl\:px-2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }

  .xl\:py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .xl\:px-3 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
  }

  .xl\:py-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important
  }

  .xl\:px-4 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
  }

  .xl\:py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important
  }

  .xl\:px-5 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
  }

  .xl\:py-05 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important
  }

  .xl\:px-05 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important
  }

  .xl\:pt-0 {
    padding-top: 0 !important
  }

  .xl\:pr-0 {
    padding-right: 0 !important
  }

  .xl\:pb-0 {
    padding-bottom: 0 !important
  }

  .xl\:pl-0 {
    padding-left: 0 !important
  }

  .xl\:pt-1 {
    padding-top: 1rem !important
  }

  .xl\:pr-1 {
    padding-right: 1rem !important
  }

  .xl\:pb-1 {
    padding-bottom: 1rem !important
  }

  .xl\:pl-1 {
    padding-left: 1rem !important
  }

  .xl\:pt-2 {
    padding-top: 2rem !important
  }

  .xl\:pr-2 {
    padding-right: 2rem !important
  }

  .xl\:pb-2 {
    padding-bottom: 2rem !important
  }

  .xl\:pl-2 {
    padding-left: 2rem !important
  }

  .xl\:pt-3 {
    padding-top: 3rem !important
  }

  .xl\:pr-3 {
    padding-right: 3rem !important
  }

  .xl\:pb-3 {
    padding-bottom: 3rem !important
  }

  .xl\:pl-3 {
    padding-left: 3rem !important
  }

  .xl\:pt-4 {
    padding-top: 4rem !important
  }

  .xl\:pr-4 {
    padding-right: 4rem !important
  }

  .xl\:pb-4 {
    padding-bottom: 4rem !important
  }

  .xl\:pl-4 {
    padding-left: 4rem !important
  }

  .xl\:pt-5 {
    padding-top: 5rem !important
  }

  .xl\:pr-5 {
    padding-right: 5rem !important
  }

  .xl\:pb-5 {
    padding-bottom: 5rem !important
  }

  .xl\:pl-5 {
    padding-left: 5rem !important
  }

  .xl\:pt-05 {
    padding-top: 0.5rem !important
  }

  .xl\:pr-05 {
    padding-right: 0.5rem !important
  }

  .xl\:pb-05 {
    padding-bottom: 0.5rem !important
  }

  .xl\:pl-05 {
    padding-left: 0.5rem !important
  }

  .xl\:w-0 {
    width: 0 !important
  }

  .xl\:w-1 {
    width: 1rem !important
  }

  .xl\:w-2 {
    width: 2rem !important
  }

  .xl\:w-3 {
    width: 3rem !important
  }

  .xl\:w-4 {
    width: 4rem !important
  }

  .xl\:w-5 {
    width: 5rem !important
  }

  .xl\:w-auto {
    width: auto !important
  }

  .xl\:w-05 {
    width: 0.5rem !important
  }

  .xl\:w-1\/2 {
    width: 50% !important
  }

  .xl\:w-1\/3 {
    width: 33.333333% !important
  }

  .xl\:w-2\/3 {
    width: 66.666667% !important
  }

  .xl\:w-1\/4 {
    width: 25% !important
  }

  .xl\:w-2\/4 {
    width: 50% !important
  }

  .xl\:w-3\/4 {
    width: 75% !important
  }

  .xl\:w-1\/5 {
    width: 20% !important
  }

  .xl\:w-2\/5 {
    width: 40% !important
  }

  .xl\:w-3\/5 {
    width: 60% !important
  }

  .xl\:w-4\/5 {
    width: 80% !important
  }

  .xl\:w-1\/6 {
    width: 16.666667% !important
  }

  .xl\:w-2\/6 {
    width: 33.333333% !important
  }

  .xl\:w-3\/6 {
    width: 50% !important
  }

  .xl\:w-4\/6 {
    width: 66.666667% !important
  }

  .xl\:w-5\/6 {
    width: 83.333333% !important
  }

  .xl\:w-1\/12 {
    width: 8.333333% !important
  }

  .xl\:w-2\/12 {
    width: 16.666667% !important
  }

  .xl\:w-3\/12 {
    width: 25% !important
  }

  .xl\:w-4\/12 {
    width: 33.333333% !important
  }

  .xl\:w-5\/12 {
    width: 41.666667% !important
  }

  .xl\:w-6\/12 {
    width: 50% !important
  }

  .xl\:w-7\/12 {
    width: 58.333333% !important
  }

  .xl\:w-8\/12 {
    width: 66.666667% !important
  }

  .xl\:w-9\/12 {
    width: 75% !important
  }

  .xl\:w-10\/12 {
    width: 83.333333% !important
  }

  .xl\:w-11\/12 {
    width: 91.666667% !important
  }

  .xl\:w-full {
    width: 100% !important
  }

  .xl\:w-screen {
    width: 100vw !important
  }

  .xl\:h-0 {
    height: 0 !important
  }

  .xl\:h-1 {
    height: 1rem !important
  }

  .xl\:h-2 {
    height: 2rem !important
  }

  .xl\:h-3 {
    height: 3rem !important
  }

  .xl\:h-4 {
    height: 4rem !important
  }

  .xl\:h-5 {
    height: 5rem !important
  }

  .xl\:h-auto {
    height: auto !important
  }

  .xl\:h-05 {
    height: 0.5rem !important
  }

  .xl\:h-full {
    height: 100% !important
  }

  .xl\:h-screen {
    height: 100vh !important
  }

  .xl\:min-h-0 {
    min-height: 0 !important
  }

  .xl\:min-h-full {
    min-height: 100% !important
  }

  .xl\:min-h-screen {
    min-height: 100vh !important
  }

  .xl\:block {
    display: block !important
  }

  .xl\:inline-block {
    display: inline-block !important
  }

  .xl\:inline {
    display: inline !important
  }

  .xl\:flex {
    display: flex !important
  }

  .xl\:inline-flex {
    display: inline-flex !important
  }

  .xl\:table {
    display: table !important
  }

  .xl\:table-caption {
    display: table-caption !important
  }

  .xl\:table-cell {
    display: table-cell !important
  }

  .xl\:table-column {
    display: table-column !important
  }

  .xl\:table-column-group {
    display: table-column-group !important
  }

  .xl\:table-footer-group {
    display: table-footer-group !important
  }

  .xl\:table-header-group {
    display: table-header-group !important
  }

  .xl\:table-row-group {
    display: table-row-group !important
  }

  .xl\:table-row {
    display: table-row !important
  }

  .xl\:flow-root {
    display: flow-root !important
  }

  .xl\:grid {
    display: grid !important
  }

  .xl\:inline-grid {
    display: inline-grid !important
  }

  .xl\:contents {
    display: contents !important
  }

  .xl\:hidden {
    display: none !important
  }

  .xl\:hover\:block:hover {
    display: block !important
  }

  .xl\:hover\:inline-block:hover {
    display: inline-block !important
  }

  .xl\:hover\:inline:hover {
    display: inline !important
  }

  .xl\:hover\:flex:hover {
    display: flex !important
  }

  .xl\:hover\:inline-flex:hover {
    display: inline-flex !important
  }

  .xl\:hover\:table:hover {
    display: table !important
  }

  .xl\:hover\:table-caption:hover {
    display: table-caption !important
  }

  .xl\:hover\:table-cell:hover {
    display: table-cell !important
  }

  .xl\:hover\:table-column:hover {
    display: table-column !important
  }

  .xl\:hover\:table-column-group:hover {
    display: table-column-group !important
  }

  .xl\:hover\:table-footer-group:hover {
    display: table-footer-group !important
  }

  .xl\:hover\:table-header-group:hover {
    display: table-header-group !important
  }

  .xl\:hover\:table-row-group:hover {
    display: table-row-group !important
  }

  .xl\:hover\:table-row:hover {
    display: table-row !important
  }

  .xl\:hover\:flow-root:hover {
    display: flow-root !important
  }

  .xl\:hover\:grid:hover {
    display: grid !important
  }

  .xl\:hover\:inline-grid:hover {
    display: inline-grid !important
  }

  .xl\:hover\:contents:hover {
    display: contents !important
  }

  .xl\:hover\:hidden:hover {
    display: none !important
  }

  .xl\:flex-1 {
    flex: 1 1 0% !important
  }

  .xl\:flex-auto {
    flex: 1 1 auto !important
  }

  .xl\:flex-initial {
    flex: 0 1 auto !important
  }

  .xl\:flex-none {
    flex: none !important
  }

  .xl\:flex-row {
    flex-direction: row !important
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important
  }

  .xl\:flex-col {
    flex-direction: column !important
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse !important
  }

  .xl\:flex-grow-0 {
    flex-grow: 0 !important
  }

  .xl\:flex-grow {
    flex-grow: 1 !important
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important
  }

  .xl\:flex-shrink {
    flex-shrink: 1 !important
  }

  .xl\:flex-wrap {
    flex-wrap: wrap !important
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .xl\:flex-no-wrap {
    flex-wrap: nowrap !important
  }

  .xl\:justify-start {
    justify-content: flex-start !important
  }

  .xl\:justify-end {
    justify-content: flex-end !important
  }

  .xl\:justify-center {
    justify-content: center !important
  }

  .xl\:justify-between {
    justify-content: space-between !important
  }

  .xl\:justify-around {
    justify-content: space-around !important
  }

  .xl\:justify-evenly {
    justify-content: space-evenly !important
  }

  .xl\:items-start {
    align-items: flex-start !important
  }

  .xl\:items-end {
    align-items: flex-end !important
  }

  .xl\:items-center {
    align-items: center !important
  }

  .xl\:items-baseline {
    align-items: baseline !important
  }

  .xl\:items-stretch {
    align-items: stretch !important
  }

  .xl\:text-left {
    text-align: left !important
  }

  .xl\:text-center {
    text-align: center !important
  }

  .xl\:text-right {
    text-align: right !important
  }

  .xl\:text-justify {
    text-align: justify !important
  }

  .xl\:overflow-auto {
    overflow: auto !important
  }

  .xl\:overflow-hidden {
    overflow: hidden !important
  }

  .xl\:overflow-visible {
    overflow: visible !important
  }

  .xl\:overflow-scroll {
    overflow: scroll !important
  }

  .xl\:overflow-x-auto {
    overflow-x: auto !important
  }

  .xl\:overflow-y-auto {
    overflow-y: auto !important
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden !important
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden !important
  }

  .xl\:overflow-x-visible {
    overflow-x: visible !important
  }

  .xl\:overflow-y-visible {
    overflow-y: visible !important
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll !important
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll !important
  }

  .xl\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important
  }

  .xl\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important
  }

  .xl\:font-hairline {
    font-weight: 100 !important
  }

  .xl\:font-thin {
    font-weight: 200 !important
  }

  .xl\:font-light {
    font-weight: 300 !important
  }

  .xl\:font-normal {
    font-weight: 400 !important
  }

  .xl\:font-medium {
    font-weight: 500 !important
  }

  .xl\:font-semibold {
    font-weight: 600 !important
  }

  .xl\:font-bold {
    font-weight: 700 !important
  }

  .xl\:font-extrabold {
    font-weight: 800 !important
  }

  .xl\:font-black {
    font-weight: 900 !important
  }

  .xl\:hover\:font-hairline:hover {
    font-weight: 100 !important
  }

  .xl\:hover\:font-thin:hover {
    font-weight: 200 !important
  }

  .xl\:hover\:font-light:hover {
    font-weight: 300 !important
  }

  .xl\:hover\:font-normal:hover {
    font-weight: 400 !important
  }

  .xl\:hover\:font-medium:hover {
    font-weight: 500 !important
  }

  .xl\:hover\:font-semibold:hover {
    font-weight: 600 !important
  }

  .xl\:hover\:font-bold:hover {
    font-weight: 700 !important
  }

  .xl\:hover\:font-extrabold:hover {
    font-weight: 800 !important
  }

  .xl\:hover\:font-black:hover {
    font-weight: 900 !important
  }

  .xl\:focus\:font-hairline:focus {
    font-weight: 100 !important
  }

  .xl\:focus\:font-thin:focus {
    font-weight: 200 !important
  }

  .xl\:focus\:font-light:focus {
    font-weight: 300 !important
  }

  .xl\:focus\:font-normal:focus {
    font-weight: 400 !important
  }

  .xl\:focus\:font-medium:focus {
    font-weight: 500 !important
  }

  .xl\:focus\:font-semibold:focus {
    font-weight: 600 !important
  }

  .xl\:focus\:font-bold:focus {
    font-weight: 700 !important
  }

  .xl\:focus\:font-extrabold:focus {
    font-weight: 800 !important
  }

  .xl\:focus\:font-black:focus {
    font-weight: 900 !important
  }

  .xl\:text-xs {
    font-size: 1rem !important
  }

  .xl\:text-sm {
    font-size: 1.2rem !important
  }

  .xl\:text-base {
    font-size: 1.5rem !important
  }

  .xl\:text-lg {
    font-size: 1.8rem !important
  }

  .xl\:text-xl {
    font-size: 2.2rem !important
  }

  .xl\:text-2xl {
    font-size: 2.6rem !important
  }

  .xl\:text-3xl {
    font-size: 3rem !important
  }

  .xl\:leading-3 {
    line-height: .75rem !important
  }

  .xl\:leading-4 {
    line-height: 1rem !important
  }

  .xl\:leading-5 {
    line-height: 1.25rem !important
  }

  .xl\:leading-6 {
    line-height: 1.5rem !important
  }

  .xl\:leading-7 {
    line-height: 1.75rem !important
  }

  .xl\:leading-8 {
    line-height: 2rem !important
  }

  .xl\:leading-9 {
    line-height: 2.25rem !important
  }

  .xl\:leading-10 {
    line-height: 2.5rem !important
  }

  .xl\:leading-none {
    line-height: 1 !important
  }

  .xl\:leading-tight {
    line-height: 1.25 !important
  }

  .xl\:leading-snug {
    line-height: 1.375 !important
  }

  .xl\:leading-normal {
    line-height: 1.5 !important
  }

  .xl\:leading-relaxed {
    line-height: 1.625 !important
  }

  .xl\:leading-loose {
    line-height: 2 !important
  }

  .xl\:list-none {
    list-style-type: none !important
  }

  .xl\:list-disc {
    list-style-type: disc !important
  }

  .xl\:list-decimal {
    list-style-type: decimal !important
  }

  .xl\:opacity-0 {
    opacity: 0 !important
  }

  .xl\:opacity-25 {
    opacity: 0.25 !important
  }

  .xl\:opacity-50 {
    opacity: 0.5 !important
  }

  .xl\:opacity-75 {
    opacity: 0.75 !important
  }

  .xl\:opacity-100 {
    opacity: 1 !important
  }

  .xl\:hover\:opacity-0:hover {
    opacity: 0 !important
  }

  .xl\:hover\:opacity-25:hover {
    opacity: 0.25 !important
  }

  .xl\:hover\:opacity-50:hover {
    opacity: 0.5 !important
  }

  .xl\:hover\:opacity-75:hover {
    opacity: 0.75 !important
  }

  .xl\:hover\:opacity-100:hover {
    opacity: 1 !important
  }

  .xl\:focus\:opacity-0:focus {
    opacity: 0 !important
  }

  .xl\:focus\:opacity-25:focus {
    opacity: 0.25 !important
  }

  .xl\:focus\:opacity-50:focus {
    opacity: 0.5 !important
  }

  .xl\:focus\:opacity-75:focus {
    opacity: 0.75 !important
  }

  .xl\:focus\:opacity-100:focus {
    opacity: 1 !important
  }

  .xl\:break-normal {
    word-wrap: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important
  }

  .xl\:break-words {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important
  }

  .xl\:break-all {
    word-break: break-all !important
  }

  .xl\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important
  }

  .xl\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important
  }

  .xl\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important
  }

  .xl\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important
  }

  .xl\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important
  }

  .xl\:text-white {
    color: #FFFFFF !important
  }

  .xl\:text-black {
    color: #000000 !important
  }

  .xl\:text-darkblue {
    color: #131A50 !important
  }

  .xl\:text-main {
    color: #1C287F !important
  }

  .xl\:text-error {
    color: #D80027 !important
  }

  .xl\:text-warning {
    color: #FF8D27 !important
  }

  .xl\:text-action {
    color: #00b78a !important
  }

  .xl\:text-success {
    color: #03CE9C !important
  }

  .xl\:text-paleYellow {
    color: #FFE665 !important
  }

  .xl\:text-gray20 {
    color: #E3E4E8 !important
  }

  .xl\:text-gray40 {
    color: #C5C6D3 !important
  }

  .xl\:text-gray60 {
    color: #8589AD !important
  }

  .xl\:text-gray80 {
    color: rgba(66, 71, 112, 1) !important
  }

  .xl\:bg-white {
    background-color: #FFFFFF !important
  }

  .xl\:bg-black {
    background-color: #000000 !important
  }

  .xl\:bg-darkblue {
    background-color: #131A50 !important
  }

  .xl\:bg-main {
    background-color: #1C287F !important
  }

  .xl\:bg-error {
    background-color: #D80027 !important
  }

  .xl\:bg-warning {
    background-color: #FF8D27 !important
  }

  .xl\:bg-action {
    background-color: #00b78a !important
  }

  .xl\:bg-success {
    background-color: #03CE9C !important
  }

  .xl\:bg-paleYellow {
    background-color: #FFE665 !important
  }

  .xl\:bg-gray20 {
    background-color: #E3E4E8 !important
  }

  .xl\:bg-gray40 {
    background-color: #C5C6D3 !important
  }

  .xl\:bg-gray60 {
    background-color: #8589AD !important
  }

  .xl\:bg-gray80 {
    background-color: rgba(66, 71, 112, 1) !important
  }

  .xl\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important
  }

  .xl\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important
  }

  .xl\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important
  }

  .xl\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important
  }

  .xl\:underline {
    text-decoration: underline !important
  }

  .xl\:line-through {
    text-decoration: line-through !important
  }

  .xl\:no-underline {
    text-decoration: none !important
  }

  .xl\:hover\:underline:hover {
    text-decoration: underline !important
  }

  .xl\:hover\:line-through:hover {
    text-decoration: line-through !important
  }

  .xl\:hover\:no-underline:hover {
    text-decoration: none !important
  }

  .xl\:focus\:underline:focus {
    text-decoration: underline !important
  }

  .xl\:focus\:line-through:focus {
    text-decoration: line-through !important
  }

  .xl\:focus\:no-underline:focus {
    text-decoration: none !important
  }

  .xl\:grid-flow-row {
    grid-auto-flow: row !important
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column !important
  }

  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense !important
  }

  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense !important
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important
  }

  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important
  }

  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important
  }

  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important
  }

  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important
  }

  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important
  }

  .xl\:grid-rows-none {
    grid-template-rows: none !important
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important
  }

  .xl\:grid-cols-none {
    grid-template-columns: none !important
  }

  .xl\:auto-rows-auto {
    grid-auto-rows: auto !important
  }

  .xl\:auto-rows-min {
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important
  }

  .xl\:auto-rows-max {
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important
  }

  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important
  }

  .xl\:auto-cols-auto {
    grid-auto-columns: auto !important
  }

  .xl\:auto-cols-min {
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important
  }

  .xl\:auto-cols-max {
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important
  }

  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important
  }

  .xl\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important
  }

  .xl\:gap-1 {
    grid-gap: 1rem !important;
    gap: 1rem !important
  }

  .xl\:gap-2 {
    grid-gap: 2rem !important;
    gap: 2rem !important
  }

  .xl\:gap-3 {
    grid-gap: 3rem !important;
    gap: 3rem !important
  }

  .xl\:gap-4 {
    grid-gap: 4rem !important;
    gap: 4rem !important
  }

  .xl\:gap-5 {
    grid-gap: 5rem !important;
    gap: 5rem !important
  }

  .xl\:gap-05 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important
  }

  .xl\:col-gap-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important
  }

  .xl\:col-gap-1 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important
  }

  .xl\:col-gap-2 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important
  }

  .xl\:col-gap-3 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important
  }

  .xl\:col-gap-4 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important
  }

  .xl\:col-gap-5 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important
  }

  .xl\:col-gap-05 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important
  }

  .xl\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important
  }

  .xl\:gap-x-1 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important
  }

  .xl\:gap-x-2 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important
  }

  .xl\:gap-x-3 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important
  }

  .xl\:gap-x-4 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important
  }

  .xl\:gap-x-5 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important
  }

  .xl\:gap-x-05 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important
  }

  .xl\:row-gap-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important
  }

  .xl\:row-gap-1 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important
  }

  .xl\:row-gap-2 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important
  }

  .xl\:row-gap-3 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important
  }

  .xl\:row-gap-4 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important
  }

  .xl\:row-gap-5 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important
  }

  .xl\:row-gap-05 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important
  }

  .xl\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important
  }

  .xl\:gap-y-1 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important
  }

  .xl\:gap-y-2 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important
  }

  .xl\:gap-y-3 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important
  }

  .xl\:gap-y-4 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important
  }

  .xl\:gap-y-5 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important
  }

  .xl\:gap-y-05 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important
  }
}